import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";

export default function CheckoutForm({ setUserAddress }) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { paymentIntent, error } = await stripe.confirmCardPayment("{clientSecret}", {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: 'User Name', // Replace with actual user info
                },
            },
            shipping: {
                address: {
                    line1: '123 Shipping St', // Replace with form data
                    city: 'City',
                    state: 'State',
                    postal_code: '12345',
                    country: 'US',
                },
                name: 'User Name',
            },
        });

        if (error) {
            setError(error.message);
        } else {
            // Address from payment intent
            const shippingAddress = paymentIntent.shipping.address;
            setUserAddress(shippingAddress); // Send address back to parent component

            // Handle successful payment logic here
        }

        setProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe || processing}>
                {processing ? "Processing..." : "Pay"}
            </button>
            {error && <div>{error}</div>}
        </form>
    );
}
