import { createContext, useState, useContext, useEffect, useCallback } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";

export const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default function UserProvider({ children }) {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const [mongoUser, setMongoUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
        console.log("Auth Status:", authStatus);
        console.log("Full User Object:", user);
        
        if (authStatus !== "authenticated" || !user) {
            console.warn("User is not authenticated yet.");
            return;
        }

        // 🔹 Retrieve authentication session
        const session = await fetchAuthSession();
        console.log("Session Object:", session);

        if (!session || !session.tokens) {
            console.error("No session tokens found.");
            setError("User session missing.");
            return;
        }

        // 🔹 Use ID Token instead of Access Token
        const idToken = session.tokens?.idToken?.toString();
        if (!idToken) {
            console.error("ID Token missing.");
            setError("Authentication ID token missing.");
            return;
        }

        console.log("🔹 ID Token Sent to API:", idToken);

        // 🔹 Extract Cognito User ID (sub)
        const cognitoId = session.tokens?.idToken?.payload?.sub;
        if (!cognitoId) {
            console.error("Cognito ID missing in token payload.");
            setError("Cognito ID missing.");
            return;
        }

        console.log("Sending request with token:", idToken, "and cognitoId:", cognitoId);

        // 🔹 API Request with correct ID Token
        const response = await axios.get(
            `https://eeg4ljudvk.execute-api.us-east-1.amazonaws.com/dev/user/${cognitoId}`,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`, // Use ID Token instead of Access Token
                    "Content-Type": "application/json",
                },
            }
        );

        // 🔹 Parse response
        const userData = response.data?.body
            ? JSON.parse(response.data.body)
            : response.data;

        setMongoUser(userData);
        console.log("✅ MongoDB User Data:", userData);
    } catch (error) {
        console.error("❌ Error fetching user data:", error);
        setError(error.message || "Failed to fetch user data");
        setMongoUser(null);
    } finally {
        setLoading(false);
    }
}, [authStatus, user]);



  useEffect(() => {
    let mounted = true;

    const initializeUser = async () => {
      if (authStatus === "authenticated" && user) {
        console.log("Initializing user data...");
        setLoading(true);
        try {
          await fetchUserData();
        } catch (error) {
          console.error("Error initializing user:", error);
        } finally {
          if (mounted) setLoading(false);
        }
      } else {
        setMongoUser(null);
        setLoading(false);
      }
    };

    initializeUser();

    return () => {
      mounted = false;
    };
  }, [authStatus, user, fetchUserData]);

  const value = {
    mongoUser,
    loading,
    error,
    refreshUser: fetchUserData,
    isAdmin: mongoUser?.role === "Admin",
    isClient: mongoUser?.role === "Client",
    isEditor: mongoUser?.role === "Editor",
    cognitoUser: user,
    userRole: mongoUser?.role,
    authStatus,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}
