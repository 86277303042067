import { useState } from "react";
import './ProductInfoMain.css'
import { Link } from "react-router-dom";

export default function ProductInfoMain(props) {


    return(
        <div className="infoContainer" style={{backgroundImage: `url(${props.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
            <div className="mainInfoArea">
                <h2 style={{fontWeight: 600, color: 'white'}}>{props.name}</h2>
                <h3 style={{fontWeight: 600, color: 'white'}}>From: ${props.startingPrice}</h3>
                <h6 style={{fontWeight: 600, color: 'white'}}> {props.included}</h6>
            </div>
            <div className="featureInfo">
                <div className="feature">
                    <h5>{props.feature1}</h5>
                    <p>{props.feature1Des}</p>
                </div>
                <div className="feature">
                    <h5>{props.feature2}</h5>
                    <p>{props.feature2Des}</p>
                </div>
                <div className="feature">
                    <h5>{props.feature3}</h5>
                    <p>{props.feature3Des}</p>
                </div>
            </div>
            <div className="buttonsArea">
                <Link to={`${props.buttonLink}`}>
                    <button id="button1" style={{width: '150px'}}>Order Now</button>
                </Link>
            </div>

        </div>
    )

}