import { useState } from "react";
import './ClientOrder.css'

import { useParams } from "react-router-dom";


export default function ClientOrder(props){
    const date = new Date(props.orderDate)
    const month = date.toDateString()
    const lastStatusEvent = props.statusHistory.length > 0 ? props.statusHistory[props.statusHistory.length - 1].status : "No status history available";


    return(
        <>
        <a href={`/admin/${props.orderId}`} style={{color: '#3c3c3c', textDecoration: 'none'}}>
        <div className="clientOrderBox" style={{border: `4px solid ${props.border}`}}>
            <div className="orderDate" style={{display: 'flex', textAlign: 'center'}}>
               <strong><p>{month}</p></strong>
            </div>
            <div className="clientInfo">
                <p>Order Number:  <strong>{props.orderId}</strong></p>
                <p>Client Name:  <strong>{props.firstName} {props.lastName}</strong></p>
                <p>Status: {lastStatusEvent}</p>
            </div>
            <div className="productValue">
                <p>Pre-Tax Price: <strong>${props.preTaxPrice}</strong></p>
                <p>Discount: <strong>${props.discount}</strong></p>
                <p>Tax:  <strong>${props.tax}</strong></p>
                <p>Total Charge: <strong>${props.totalPrice}</strong> </p>
            </div>
        </div>
        </a>
        </>
    )
}