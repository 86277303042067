import './checkoutModal.css'; // Import the styles
import { IoMdCloseCircle } from "react-icons/io";


export default function Modal({ show, onClose, children }) {
  if (!show) {
    return null; // Don't render the modal if "show" is false
  }

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}><IoMdCloseCircle color='firebrick' size={25}/></button>
        {children}
      </div>
    </div>
  );
}