import { Link, useNavigate } from 'react-router-dom'
import './ThankYouPage.css'

export default function ThankYouPage() {
    const navigate = useNavigate()


    return(
        <main>
            <h1>Thank You!  Your payment has been processed.</h1>
            <div className='nextBox'>
                <h2>The next step is to upload your image/s for our team</h2>
                <Link to={`/${req.params.userId}/${req.params.orderId}/upload-images`}>
                    <button>Upload Your Images</button>
                </Link>

            </div>
        </main>
    )
}