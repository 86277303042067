import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUpload, FaDownload } from 'react-icons/fa';
import { IoChevronBackCircleOutline, IoChevronForwardCircle } from "react-icons/io5";
import ReactLoading from 'react-loading';
import { UserContext } from '../../../context/UserProvider';
import axios from 'axios';
import './editorsPage.css';
import Navbar from '../../../components/navbars/Navbar';
import { useUser } from "../../../context/UserProvider";

const editingStage = ['Sent to editing team', 'Editing started', 'Waiting for client approval', 'Editing completed'];

export default function EditorsPage() {
  const navigate = useNavigate()
  const {mongoUser, cognitoUser } = useUser()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [expand, setExpand] = useState({});
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [expandedClientId, setExpandedClientId] = useState(null);
  const [uploadedImages, setUploadedImages] = useState({});
  const [uploadedStatus, setUploadedStatus] = useState({});
  const [albumFiles, setAlbumFiles] = useState({});
  const [msg, setMsg] = useState('');
  const [selectedStatus, setSelectedStatus] = useState({}); // For tracking selected status per order

  // Calculate total items (albums + edits) in the order
  const calculateTotalItems = (order) => {
    const albumCount = order.albums ? order.albums.length : 0;
    const editCount = order.products ? order.products.filter(product => product.category === 'Editing').length : 0;
    return albumCount + editCount;
  };

  const getLatestStatus = (statusHistory) => {
    return statusHistory[statusHistory.length - 1]?.status || 'Unknown';
  };

  // Overview Counts: Calculate active, completed, and awaiting client approval counts
  const calculateOverviewCounts = () => {
    let active = 0;
    let completed = 0;
    let awaitingClientApproval = 0;

    data.forEach(order => {
      const latestStatus = getLatestStatus(order.statusHistory);
      if (latestStatus === 'Sent to editing team' || latestStatus === 'Editing started') {
        active++;
      } else if (latestStatus === 'Editing completed') {
        completed++;
      } else if (latestStatus === 'Waiting for client approval') {
        awaitingClientApproval++;
      }
    });

    return { active, completed, awaitingClientApproval };
  };

  // Function to manually update status from dropdown
  const handleStatusChange = async (orderId, newStatus) => {
    try {
      await axios.put(`http://localhost:9000/orders/api/update-status/${orderId}`, {
        status: newStatus,
        readyForEditing: newStatus === 'Sent to editing team',
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
        },
      });
      console.log(`Order ${orderId} status updated to: ${newStatus}`);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  // Function to send status update to backend when all images are uploaded
  const updateOrderStatus = async (orderId, isReadyForEditing) => {
    try {
      await axios.put(`http://localhost:9000/orders/api/update-status/${orderId}`, {
        status: isReadyForEditing ? 'Sent to editing team' : 'Awaiting user images',
        readyForEditing: isReadyForEditing,
        mongoUser: {
          firstName: mongoUser.firstName,
          lastName: mongoUser.lastName,
        },
      });
      console.log(`Order ${orderId} status updated successfully to ${isReadyForEditing ? 'Sent to editing team' : 'Awaiting user images'}`);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  // Function to check if all images have been uploaded for all albums in the specific order
  const checkIfAllImagesUploaded = (order) => {
    const orderId = order._id;
    const allAlbumsUploaded = order.albums.every(album => uploadedStatus[orderId]?.[album._id]);
    return allAlbumsUploaded;
  };

  // Function to handle album image uploads
  const handleAlbumFileChange = async (orderId, albumId, albumName, albumSize, albumPages, index, albumIndex, e) => {
    const files = e.target.files;

    // Fetch current image count for the album within the specific order
    const currentImageCount = albumFiles[orderId]?.[albumId] || 0;
    const maxImagesAllowed = 2 * albumPages;

    // Check if file selection exceeds maximum allowed
    if (currentImageCount + files.length > maxImagesAllowed) {
      alert(`You can upload up to ${maxImagesAllowed} images for this album.`);
      return;
    }

    const formData = new FormData();
    formData.append('clientId', data[index].user);
    formData.append('orderId', orderId);
    formData.append('albumId', albumId);
    formData.append('albumName', albumName);
    formData.append('albumSize', albumSize);
    formData.append('albumPages', albumPages);
    formData.append('albumIndex', albumIndex);
    formData.append('index', index);

    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    setMsg('Uploading...');

    try {
      await axios.post(
        `http://localhost:9000/api/gallery/gallery-images/editor/${data[index].user}/${orderId}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      setMsg('Upload successful');

      // Update the specific album within the specific order
      setUploadedImages((prevState) => ({
        ...prevState,
        [orderId]: {
          ...prevState[orderId],
          [albumId]: URL.createObjectURL(files[0]), // Store the first uploaded image's URL
        },
      }));

      setUploadedStatus((prevStatus) => ({
        ...prevStatus,
        [orderId]: {
          ...prevStatus[orderId],
          [albumId]: true, // Mark album as having uploaded images
        },
      }));

      // Update the current image count after upload for the specific album in the order
      setAlbumFiles((prevState) => ({
        ...prevState,
        [orderId]: {
          ...prevState[orderId],
          [albumId]: currentImageCount + files.length, // Update image count for album
        },
      }));

      // Check if all images are uploaded for all albums in this specific order
      if (checkIfAllImagesUploaded(data[index])) {
        updateOrderStatus(orderId, true);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setMsg('Upload failed');
    }
  };

  // Function to trigger album file input
  const triggerAlbumInput = (albumId) => {
    document.getElementById(`albumFileInput-${albumId}`).click();
  };

  // Function to download images for an album
  const handleDownloadImages = (albumId) => {
    alert(`Download images for albumId: ${albumId}`);
  };

  // Function to download all files for a specific order
  const downloadOrderFiles = async (clientId, orderId) => {
    try {
      const response = await axios.get(
        `http://localhost:9000/api/gallery//download-order/${clientId}/${orderId}`, 
        { responseType: 'blob' } // Set to blob to handle file download
      );

      // Create a download link for the ZIP file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `order-${orderId}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading order files:', error);
    }
  };

  // Function to download a specific album or edit
  const downloadItemFiles = async (clientId, orderId, itemPath) => {
    try {
      const response = await axios.get(
        `http://localhost:9000/api/gallery/download-item/${clientId}/${orderId}/${itemPath}`, 
        { responseType: 'blob' }
      );

      // Create a download link for the file/folder
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${itemPath}.zip`); // Assuming ZIP is used for folders
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading item files:', error);
    }
  };

  // Toggle order ID expansion to show the full ID
  const toggleOrderIdDisplay = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  // Toggle client ID expansion to show the full ID
  const toggleClientIdDisplay = (clientId) => {
    setExpandedClientId(expandedClientId === clientId ? null : clientId);
  };

  // Toggle order expansion to show albums and edits
  const toggleOrderExpansion = (orderId) => {
    setExpand((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };

  useEffect(() => {
    if(!cognitoUser){
      navigate('/login')
      return
    } else if(!(cognitoUser.role !== "Admin" || cognitoUser.role !== "Editors")){
      navigate('/')
      return
    }
    const getData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${import.meta.env.BASE_API}/orders/api/editing-orders`);
        const editingOrders = response.data;
        if (editingOrders && editingOrders.length > 0) {
          setData(editingOrders);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [cognitoUser, navigate]);

  const { active, completed, awaitingClientApproval } = calculateOverviewCounts();

  return (
    <main style={{ backgroundImage: 'radial-gradient(lightgrey, #3c3c3c)' }}>
      <Navbar />
      <div className="header">
        <h1>Editor's Dashboard</h1>
      </div>
      
      {/* Overview Section */}
      <div className="overview" style={{display: 'flex', justifyContent: 'space-around'}}>
        <div className="overviewItem">
          <h3>Active</h3>
          <p>{active}</p>
        </div>
        <div className="overviewItem">
          <h3>Completed</h3>
          <p>{completed}</p>
        </div>
        <div className="overviewItem">
          <h3>Awaiting Client Approval</h3>
          <p>{awaitingClientApproval}</p>
        </div>
      </div>

      <div className="ordersArea">
        {loading && <ReactLoading size={25} />}
        {data.length > 0 &&
          data.map((item, index) => {
            const latestStatus = getLatestStatus(item.statusHistory);
            const isExpanded = expand[item._id];
            const totalItems = calculateTotalItems(item);  // Calculate total items

            return (
              <div className="orderItem" key={index} style={{ backgroundColor: '#f5f5f5' }}>
                <div className="orderInfoArea">
                  <div className="orderInfoLeft">
                    <h6>
                      Order#: {' '}
                      <span style={{ cursor: 'pointer' }} onClick={() => toggleOrderIdDisplay(item._id)}>
                        {expandedOrderId === item._id ? (
                          <>
                            {item._id} <IoChevronBackCircleOutline />
                          </>
                        ) : (
                          <>
                            {item._id.slice(0, 8)}... <IoChevronForwardCircle />
                          </>
                        )}
                      </span>
                      <div>
                        <button onClick={() => downloadOrderFiles(item.user, item._id)} className='button1'>
                          <FaDownload /> Download All Files
                        </button>
                      </div>
                    </h6>
                    <h6>
                      Client#: {' '}
                      <span style={{ cursor: 'pointer' }} onClick={() => toggleClientIdDisplay(item.user)}>
                        {expandedClientId === item.user ? (
                          <>
                            {item.user} <IoChevronBackCircleOutline />
                          </>
                        ) : (
                          <>
                            {item.user.slice(0, 8)}... <IoChevronForwardCircle />
                          </>
                        )}
                      </span>
                    </h6>
                    <h6>Order Date: <strong>{new Date(item.createdAt).toDateString()}</strong></h6>
                    <h6 style={{ color: 'dodgerblue' }}>Total Items: <strong>{totalItems}</strong></h6>
                    <div className="servicesNotification">
                      {item.albums?.length > 0 && (
                        <div className="servicesLabel" style={{ backgroundColor: 'darkOrchid' }}>
                          <p>Album Design</p>
                        </div>
                      )}
                      {item.products?.length > 0 && (
                        <div className="servicesLabel" style={{ backgroundColor: 'dodgerblue' }}>
                          <p>Photo Edit</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="orderExpandIcon">
                    <span onClick={() => toggleOrderExpansion(item._id)}>
                      {isExpanded ? <IoChevronBackCircleOutline size={24} /> : <IoChevronForwardCircle size={24} />}
                    </span>
                  </div>
                </div>

                {/* Status Update Dropdown */}
                <div className="statusDropdown">
                  <label htmlFor="status">Change Status: </label>
                  <select
                    id="status"
                    value={selectedStatus[item._id] || latestStatus} // Default to current status
                    onChange={(e) => {
                      const newStatus = e.target.value;
                      setSelectedStatus((prevStatus) => ({
                        ...prevStatus,
                        [item._id]: newStatus,
                      }));
                      handleStatusChange(item._id, newStatus);
                    }}
                  >
                    {editingStage.map((status, idx) => (
                      <option key={idx} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Albums and Edits List */}
                {isExpanded && (
                  <div className="orderDetails">
                    {/* Albums */}
                    {item.albums?.length > 0 && (
                      <div className="albumsList">
                        <h4>Albums</h4>
                        {item.albums.map((album, albumIndex) => (
                          <div key={albumIndex} className="albumItem" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>{album.albumName} - {album.albumOptions.size}</p>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <p style={{ color: 'dodgerblue' }}>
                                Images Uploaded: {albumFiles[item._id]?.[album._id] || 0} / {2 * album.albumOptions.pages}
                              </p>
                              {uploadedStatus[item._id]?.[album._id] ? (
                                <>
                                  <p style={{ color: 'darkorchid', cursor: 'pointer' }} onClick={() => triggerAlbumInput(album._id)}>
                                    Add more images
                                  </p>
                                  <input
                                    type="file"
                                    id={`albumFileInput-${album._id}`}
                                    accept="image/*"
                                    multiple
                                    hidden
                                    onChange={(e) => handleAlbumFileChange(item._id, album._id, album.albumName, album.albumOptions.size, album.albumOptions.pages, index, albumIndex, e)}
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    id={`albumFileInput-${album._id}`}
                                    accept="image/*"
                                    multiple
                                    hidden
                                    onChange={(e) => handleAlbumFileChange(item._id, album._id, album.albumName, album.albumOptions.size, album.albumOptions.pages, index, albumIndex, e)}
                                  />
                                  <button onClick={() => triggerAlbumInput(album._id)}  className='button1'>
                                    <FaUpload /> Upload Images
                                  </button>
                                </>
                              )}
                              <button onClick={() => downloadItemFiles(item.user, item._id, album._id)}  className='button1'>
                                <FaDownload /> Download Album Files
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Edits */}
                    {item.products?.length > 0 && (
                      <div className="editsList">
                        <h4>Edits</h4>
                        {item.products.filter(product => product.category === 'Editing').map((edit, editIndex) => (
                          <div key={edit.productId} className="editItem" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p>{edit.productName}</p>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <button onClick={() => downloadItemFiles(item.user, item._id, edit.productId)}>
                                <FaDownload /> Download Edit Files
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </main>
  );
}
