import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-black-500.png';
import './Navbar.css';
import { FaShoppingCart } from 'react-icons/fa';
import { RxHamburgerMenu } from 'react-icons/rx';
import { RiCloseLine } from 'react-icons/ri';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { CartContext } from '../../context/CartContext'
import NavbarProduct from './navbarProduct';
import axios from 'axios';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { useUser } from '../../context/UserProvider';
import { signOut } from 'aws-amplify/auth';



export default function Navbar() {
  const { mongoUser, loading, userRole, cognitoUser, isAdmin, isClient, authStatus, setUser} = useUser()
  const {  signOut } = useAuthenticator()
  const [mongoUserState, setMongoUserState] = useState(null);

  





  // const {cartItems} = useContext(CartContext)
  
  const [userAttributes, setUserAttributes] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileProducts, setMobileProducts] = useState(null);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [albumData, setAlbumData] = useState([]);
  const [productData, setProductData] = useState([]);
  const dropdownRef = useRef(null);
  const navRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1001);

//   const wallArt = useMemo(() => productData.filter(p => p.category === "Edge-Prints"), [productData]);
// const editing = useMemo(() => productData.filter(p => p.category === "Editing"), [productData]);
// const imageBox = useMemo(() => productData.filter(p => p.category === "Image-Box"), [productData]);




const handleSignOut = async () => {
  try {
      console.log("Signing out...");

      await signOut(); // Cognito sign-out

      setUser(null); // Reset UserContext (important to clear state)
      
      window.location.href = "https://albumsandart.com"; // Redirect after logout

  } catch (error) {
      console.error("Error signing out:", error);
  }
};
  

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1001);
    
    window.addEventListener('resize', handleResize);
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = (link) => {
    clearTimeout(timeoutRef.current);
    setHoveredLink(link);
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      if (!dropdownRef.current.matches(':hover')) {
        setDropdownOpen(false);
        setHoveredLink(null);
      }
    }, 300);
  };

  const handleDropdownMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setDropdownOpen(true);
  };

  const handleDropdownMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
      setHoveredLink(null);
    }, 300);
  };

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    if (mobileMenu) {
      setMobileProducts(null);
    }
  };

  const toggleMobileProducts = (category) => {
    setMobileProducts((prev) => (prev === category ? null : category));
  };

  const getFirstName = (fullName) => {
    return fullName ? fullName.split(' ')[0] : '';
  };

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        if (authStatus === 'authenticated' && cognitoUser?.attributes) {
          console.log('Cognito User Attributes:', cognitoUser.attributes);
          console.log('MongoDB User:', mongoUser);
          console.log('User Role:', mongoUser?.role || 'No role found');
          setUserAttributes(cognitoUser.attributes);
      }
      } catch (error) {
          console.error('Error fetching user attributes:', error);
      }
  };
  

    if (authStatus === 'authenticated') {
        fetchAttributes();
    }
}, [authStatus]);

useEffect(() => {
  const fetchAttributes = async () => {
      try {
        if (authStatus === 'authenticated' && cognitoUser?.attributes) {
          console.log('Cognito User Attributes:', cognitoUser.attributes);
          console.log('MongoDB User:', mongoUser);
          console.log('User Role:', mongoUser?.role || 'No role found');
          setUserAttributes(cognitoUser.attributes);
      }
      } catch (error) {
          console.error('Error fetching user attributes:', error);
      }
  };

  if (authStatus === 'authenticated') {
      fetchAttributes();
  }
}, [authStatus, cognitoUser]);


useEffect(() => {
  console.log('MongoDB User in Navbar:', mongoUser);
  console.log('User Role:', userRole);
}, [mongoUser, userRole]);

if (loading) {
  return <div>Loading...</div>;
}
  return (
    <nav className={`navbar ${dropdownOpen ? 'expand-navbar' : ''}`} ref={navRef}>
      <a href='/'>
        <img src={logo} width={125} alt='logo image' />
      </a>

      <div className='hamburger-menu'>
        {mobileMenu ? (
          <RiCloseLine size={25} onClick={toggleMobileMenu} />
        ) : (
          <RxHamburgerMenu size={25} onClick={toggleMobileMenu} />
        )}
      </div>

      <div className="nav-wrapper">
        <div 
          className={`nav-links ${mobileMenu ? 'mobileNavBar' : ''}`}
          onMouseLeave={handleMouseLeave}
        >
          {/* Add welcome message and user info at the top of mobile menu */}
            {isMobile && mobileMenu && authStatus === 'authenticated' && (
              <div className="mobile-user-section">
                <span className="mobile-welcome">
                  Welcome, {}!
                </span>
                {authStatus === 'authenticated' && mongoUser?._id && mongoUser?.role === "Client" &&(
                  <>
                    {console.log("MongoUser for My Orders:", mongoUser?._id)}
                    <Link to={`/${mongoUser?._id}/orders`}>My Orders</Link>
                  </>
                )}

              </div>
            )}
          {authStatus === 'authenticated' && mongoUser?.role === "Admin"   &&  (
            <>
              <Link to={'/admin/outstanding-orders'}>Orders</Link>
              <Link to={'/admin/products'}>Products</Link>
              <Link to={'/admin/editors'}>Editing</Link>
              <Link to={'/admin/add-products'}>Add Products</Link>
            </>
          )}
         
         
          <a 
            href="#" // Prevent navigation for mobile
            onMouseEnter={() => handleMouseEnter('albums')}
            onClick={(e) => {
              e.preventDefault(); // Prevent the default navigation behavior
              toggleMobileProducts('albums'); // Show the albums products
            }}
          >
            Albums
          </a>
          <a 
            href="#" 
            onMouseEnter={() => handleMouseEnter('editing')}
            onClick={(e) => {
              e.preventDefault();
              toggleMobileProducts('editing');
            }}
          >
            Editing
          </a>
          <a 
            href="#" 
            onMouseEnter={() => handleMouseEnter('wallArt')}
            onClick={(e) => {
              e.preventDefault();
              toggleMobileProducts('wallArt');
            }}
          >
            Wall Art
          </a>
          <a 
            href="#" 
            onMouseEnter={() => handleMouseEnter('imageBox')}
            onClick={(e) => {
              e.preventDefault();
              toggleMobileProducts('imageBox');
            }}
          >
            Image Box
          </a>
          <Link to='/about'>About</Link>
          {authStatus === "authenticated" ? (
            mongoUser ? (
              <Link to={`/${mongoUser._id}/orders`}>My Orders</Link>
            ) : (
              <span>Loading...</span> // or use a disabled button
            )
          ) : null}          
        </div>


        <div 
          className={`product-dropdown ${dropdownOpen ? 'open' : ''}`} 
          ref={dropdownRef}
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={handleDropdownMouseLeave}
        >
          {!isMobile && dropdownOpen && hoveredLink === 'albums' && (
            <>
            <NavbarProduct
              name={"Lux"}
              image={'https://albums-and-art-product.s3.us-east-1.amazonaws.com/Albums/Lux/LuxNavbar.png'}
              learnLink={`/albums/Lux`}
              orderLink={`/shop/albums/Lux`}
              
            />
            <NavbarProduct
              name={"Gallery"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Gallery/GalleryNavbar.png'}
              learnLink={`/albums/Gallery`}
              orderLink={`/shop/albums/Gallery`}
              
            />
            <NavbarProduct
              name={"Economy"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Economy/EconomyAlbumNavbar.png'}
              learnLink={`/albums/Economy`}
              orderLink={`/shop/albums/Economy`}
              
            />
            <NavbarProduct
              name={"Press Book"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Press-Book/PressBookNavbar.png'}
              learnLink={`/albums/Press-Book`}
              orderLink={`/shop/albums/Press-Book`}
            />
            </>
          )
          }
          {!isMobile && dropdownOpen && hoveredLink === 'wallArt' &&  (
            <>
            <NavbarProduct 
              name={"Canvas"}
              image={'https://albums-and-art-product.s3.us-east-1.amazonaws.com/Canvas/canvasNavbar.png'}
              learnLink={`/products/wall-art`}
              orderLink={`/shop/products/wall-art`}
              />
            <NavbarProduct 
              name={"Edge Prints"}
              image={'https://albums-and-art-product.s3.us-east-1.amazonaws.com/Edge-Prints/EdgeNavbar.png'}
              learnLink={`/products/Edge-Prints`}
              orderLink={`/shop/products/Edge-Prints`}
              />
              </>
          )}
          {!isMobile && dropdownOpen && hoveredLink === "editing" && (
            <>
            <NavbarProduct
              name={"Album Revision (1 Revision)"}
              image={'https://albums-and-art-product.s3.us-east-1.amazonaws.com/Editing/album-design.PNG'}
              caveat={"Album Purchase Required"}
              />
            <NavbarProduct
              name={"Album Revision (2 Revisions)"}
              image={'https://albums-and-art-product.s3.us-east-1.amazonaws.com/Editing/album-design.PNG'}
              caveat={"Album Purchase Required"}
              />
              </>
          )}
          {!isMobile && dropdownOpen && hoveredLink === "imageBox" && <h2>Coming Soon!</h2>}
        </div>
      </div>

      <div className={`cart-profile ${mobileMenu ? 'mobile' : 'desktop'}`}>
        <Link to='/cart' className='cart-link'>
          {/* <FaShoppingCart /> {cartItems.length} */}
        </Link>

       
      
        {authStatus === 'authenticated' ? (
          <div className='user-info'>
            {/* Using mongoUser for the orders link if needed */}
            {(mongoUser || authStatus === 'authenticated') && 
              <>
                <span style={{color: '#333'}}>
                  {/* Use cognitoUser attributes instead of userAttributes */}
                  Welcome{mongoUser?.firstName && `, ${mongoUser.firstName}`}!
                </span>
                <p 
                  onClick={handleSignOut} 
                  className='logout-button' 
                  style={{ color: '#0d6efd', cursor: 'pointer', marginTop: '0px' }}
                >
                  <FiLogOut size={20} /> Sign Out
                </p>
              </> 
            }
          </div>
      ) : (
          <Link to='/login'>Login</Link>
      )}
      </div>

      {/* Mobile Product dropdowns - These look fine but let's add role-based ordering */}
      {isMobile && mobileMenu && mobileProducts === 'albums' && (
        <div className='mobile-product-dropdown'>
          <div className='back-button' onClick={() => setMobileProducts(null)}>
            <RiArrowLeftSLine size={24} /> Back
          </div>
            
              <NavbarProduct
              name={"Lux"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Lux/LuxNavbar.png'}
              learnLink={`/albums/Lux`}
              orderLink={`/shop/albums/Lux`}
              
            />
              <NavbarProduct
              name={"Gallery"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Gallery/GalleryNavbar.png'}
              learnLink={`/albums/Gallery`}
              orderLink={`/shop/albums/Gallery`}
              
            />
              <NavbarProduct
              name={"Economy"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Economy/EconomyAlbumNavbar.png'}
              learnLink={`/albums/Economy`}
              orderLink={`/shop/albums/Economy`}
              
            />
              <NavbarProduct
              name={"PressBook"}
              image={'https://albums-and-art-product.s3.amazonaws.com/Albums/Press-Book/PressBookNavbar.png'}
              learnLink={`/albums/PressBook`}
              orderLink={`/shop/albums/PressBook`}
              
            />
            
          </div>
        )}
        {isMobile && mobileMenu && mobileProducts === 'wallArt' && (
          <div className='mobile-product-dropdown'>
            <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
            
              <NavbarProduct 
                name={"Edge-Prints"}
                image={"https://albums-and-art-product.s3.amazonaws.com/Edge-Prints/Edge-Print-with-Coal-Linen.png"}    
                learnLink={`/products/Edge-Prints`}
                orderLink={`/shop/products/Edge-Prints`}
              />
            
          </div>
        )}
        {isMobile && mobileMenu && mobileProducts === 'editing' && (
          <div className='mobile-product-dropdown'>
            <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
              <NavbarProduct
              name={"Single Revision"}
              image={"https://albums-and-art-product.s3.amazonaws.com/Editing/EditingImage.PNG"}
              caveat={"Album Purchase Required"}
            />
              <NavbarProduct
              name={"Double Revision"}
              image={"https://albums-and-art-product.s3.amazonaws.com/Editing/EditingImage.PNG"}
              caveat={"Album Purchase Required"}
            />
            
          </div>
        )}
        {isMobile && mobileMenu && mobileProducts === 'imageBox' && (
          <div className='mobile-product-dropdown'>
            <div className='back-button' onClick={() => setMobileProducts(null)}>
              <RiArrowLeftSLine size={24} /> Back
            </div>
            <h2>Coming Soon!</h2>
          </div>
        )}
    </nav>
  );
}