// CartContext.jsx
import { createContext, useState, useContext, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import axios from 'axios';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const { authStatus, user } = useAuthenticator((context) => [
        context.authStatus,
        context.user
    ]);
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCart = async () => {
        try {
            setLoading(true);
            if (authStatus === 'authenticated' && user) {
                const token = user.signInUserSession.idToken.jwtToken;
                
                const response = await axios.get('/cart', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setCartItems(response.data.items || []);
            } else {
                const localCart = localStorage.getItem('guestCart');
                if (localCart) {
                    setCartItems(JSON.parse(localCart));
                } else {
                    setCartItems([]);
                }
            }
        } catch (error) {
            console.error('Error fetching cart:', error);
            setCartItems([]);
        } finally {
            setLoading(false);
        }
    };

    const addToCart = async (product) => {
        try {
            if (authStatus === 'authenticated' && user) {
                const token = user.signInUserSession.idToken.jwtToken;
                const response = await axios.post('/cart/add', 
                    { productId: product._id, quantity: 1 },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setCartItems(response.data.items);
            } else {
                const updatedCart = [...cartItems, { ...product, quantity: 1 }];
                setCartItems(updatedCart);
                localStorage.setItem('guestCart', JSON.stringify(updatedCart));
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            throw error;
        }
    };

    const removeFromCart = async (productId) => {
        try {
            if (authStatus === 'authenticated' && user) {
                const token = user.signInUserSession.idToken.jwtToken;
                const response = await axios.delete(`/cart/${productId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setCartItems(response.data.items);
            } else {
                const updatedCart = cartItems.filter(item => item._id !== productId);
                setCartItems(updatedCart);
                localStorage.setItem('guestCart', JSON.stringify(updatedCart));
            }
        } catch (error) {
            console.error('Error removing from cart:', error);
            throw error;
        }
    };

    const updateQuantity = async (productId, quantity) => {
        try {
            if (authStatus === 'authenticated' && user) {
                const token = user.signInUserSession.idToken.jwtToken;
                const response = await axios.put(`/cart/${productId}`, 
                    { quantity },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setCartItems(response.data.items);
            } else {
                const updatedCart = cartItems.map(item => 
                    item._id === productId ? { ...item, quantity } : item
                );
                setCartItems(updatedCart);
                localStorage.setItem('guestCart', JSON.stringify(updatedCart));
            }
        } catch (error) {
            console.error('Error updating quantity:', error);
            throw error;
        }
    };

    const mergeGuestCart = async () => {
        try {
            const guestCart = localStorage.getItem('guestCart');
            if (guestCart && authStatus === 'authenticated' && user) {
                const token = user.signInUserSession.idToken.jwtToken;
                await axios.post('/cart/merge', 
                    { items: JSON.parse(guestCart) },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                localStorage.removeItem('guestCart');
                await fetchCart();
            }
        } catch (error) {
            console.error('Error merging cart:', error);
            throw error;
        }
    };

    // Calculate cart totals
    const cartTotal = cartItems.reduce((total, item) => 
        total + (item.price * (item.quantity || 1)), 0
    );

    const itemCount = cartItems.reduce((count, item) => 
        count + (item.quantity || 1), 0
    );

    useEffect(() => {
        fetchCart();
    }, [authStatus, user]);

    return (
        <CartContext.Provider value={{
            cartItems,
            loading,
            addToCart,
            removeFromCart, // Now properly defined
            updateQuantity,
            mergeGuestCart,
            cartTotal,
            itemCount,
            refreshCart: fetchCart
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export default CartProvider;
