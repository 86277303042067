import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import ReactLoading from 'react-loading';
import { FaRegImage } from "react-icons/fa";
import ProductCard from '../../../components/cards/productCard';
import './adminProductPage.css';
import Modal from 'react-modal';
import { useUser } from "../../../context/UserProvider";

export default function AdminProductPage() {
    const {mongoUser, cognitoUser} = useUser()
    const navigate = useNavigate()
    // State
    const [loading, setLoading] = useState(false);
    const [albumData, setAlbumData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [activeTab, setActiveTab] = useState('albums'); // Manage tab state
    const [categoryFilter, setCategoryFilter] = useState('');
    const [sizeFilter, setSizeFilter] = useState('');
    const [errors, setErrors] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null); // To store the album or product being edited
    const [editingOption, setEditingOption] = useState(null); // To store the specific option being edited
    const [error, setError] = useState('')
    // Categories and sizes for filtering
    const categories = [
        "Albums", "Prints", "Image-Box", "Editing", "Frames", "Canvas", "Metal-Art", 
        "Acrylic-Blocks", "Edge-Prints", "Acrylic-Art"
    ];

    const sizes = [
        '4x6', '4x7', '4x8', '4x9', '4x10', '4x12', '5x5', '5x7', '5x8', '5x10', '5x30',
        '5½x7', '5½x14', '6x6', '6x8', '6x9', '6x18', '7x7', '7x10', '7x11', '8x8', 
        '8x10', '8x12', '8x16', '8x20', '8x24', '8½x11', '9x12', '10x10', '10x13', 
        '10x14', '10x15', '10x16', '10x20', '10x26', '10x30', '11x14', '11x16', '11x22', 
        '11x28', '12x12', '12x16', '12x18', '12x24', '12x36', '13x20', '14x20', '14x21', 
        '14x22', '15x20', '15x24', '15x30', '16x16', '16x20', '16x24', '16x48', '20x20', 
        '20x24', '20x30', '24x24', '24x30', '24x36', '30x30', '30x40', '40x50*', '40x60*'
    ];

    // Fetch album and product data
    useEffect(() => {
        
        if(!cognitoUser || cognitoUser.role !== "Admin"){
            navigate('/login')
            return
        }
        const getData = async () => {
            setLoading(true);
            try {
                const [albumResult, productResult] = await Promise.all([
                    axios.get(`${import.meta.env.BASE_API}/albums/all-Albums`),
                    axios.get(`${import.meta.env.BASE_API}/products/all-products`),
                ]);

                if (albumResult.data) {
                    setAlbumData(albumResult.data);
                }

                if (productResult.data) {
                    setProductData(productResult.data);
                }
            } catch (err) {
                setErrors(prevErrors => [...prevErrors, err.message]);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [cognitoUser, navigate]);

    // Handle category and size filter for products
    const filteredProducts = productData.filter(product => {
        return (!categoryFilter || product.category === categoryFilter) && 
               (!sizeFilter || product.size === sizeFilter);
    });

    // Function to open the modal and set the editing item
    const openModal = (item, option = null) => {
        setEditingItem(item);
        setEditingOption(option); // Set the specific option (albumOptions or productOptions)
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setEditingItem(null);
        setEditingOption(null);
    };

    // Handle input changes for the album/product itself
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingItem(prevItem => ({
            ...prevItem,
            [name]: value
        }));
    };

    // Handle changes for the specific options (albumOptions or productOptions)
    const handleOptionChange = (e, index, optionType) => {
        const { name, value } = e.target;
        const updatedOptions = editingItem[optionType].map((option, optIndex) => {
            if (optIndex === index) {
                return { ...option, [name]: value };
            }
            return option;
        });

        setEditingItem(prevItem => ({
            ...prevItem,
            [optionType]: updatedOptions
        }));
    };

    // Handle image upload
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Logic for uploading the file
        }
    };
    // Save Album Changes ***************************************
    const saveAlbumChanges =async () => {
        try{
            const response = await axios.put(`${import.meta.env.BASE_API}/albums/${editingItem._id}`,
                editingItem
            )
            closeModal()
        }
        catch(err){
            console.log(err)
            setError("There was an error saving the album information")
        }
    }
    // Save Product changes
    const saveProductChanges = async () => {
        try {
            const response = await axios.put(
                `${import.meta.env.BASE_API}/products/${editingItem._id}`,
                editingItem
            );
            closeModal(); // Close the modal after saving
            // Optionally refetch the data or update the UI
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };


// ****************************  MAIN BODY  ******************************************************
    return (
        <main>
            {loading ? <ReactLoading size={55} type='spin' />
            :    
            <div>
                <h1>Admin Products Page</h1>
                <div className='tabs'>
                    {/* Tab Navigation */}
                    <button onClick={() => setActiveTab('albums')}>Albums</button>
                    <button onClick={() => setActiveTab('products')}>Products</button>
                </div>

                <div>
                    {/* Albums Tab */}
                    {activeTab === 'albums' && (
                        <div className='productArea'>
                            {albumData.map((album) => (
                                album.albumOptions.map((option, optIndex) => (                                        
                                    <ProductCard 
                                        key={option._id}
                                        name={album.albumName} 
                                        image={album.images[0]?.url || <FaRegImage size={25}/>}  
                                        size={option.size}  
                                        price={option.price}  
                                        pages={option.pages}
                                        cost={option.cost}
                                        handleEdit={() => openModal(album, option)} // Open modal with album and option
                                        item={album} 
                                    />
                                ))
                            ))}
                        </div>
                    )}

                    {/* Products Tab */}
                    {activeTab === 'products' && (
                        <div className='viewArea'>
                            {/* Filters */}
                            <div>
                                <label htmlFor='category'>Category:</label>
                                <select
                                    id='category'
                                    value={categoryFilter}
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                >
                                    <option value=''>All Categories</option>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>

                                <label htmlFor='size'>Size:</label>
                                <select
                                    id='size'
                                    value={sizeFilter}
                                    onChange={(e) => setSizeFilter(e.target.value)}
                                >
                                    <option value=''>All Sizes</option>
                                    {sizes.map((size, index) => (
                                        <option key={index} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{display:'flex', gap: '3rem', justifyContent: 'center', marginTop: '10px'}}>
                            {/* Display filtered products */}
                            {filteredProducts.map((product, index) => (
                                <ProductCard 
                                    key={index}
                                    name={product.productName} 
                                    image={product.images[0] || null}  
                                    size={product.size}  
                                    price={product.price}  
                                    cost={product.cost}
                                    category={product.category}
                                    handleEdit={() => openModal(product, product.productOptions)} // Open modal with product and options
                                    item={product} 
                                />
                            ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            }

            {/* Modal for editing */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Item"
            >
                {editingItem && (
                    <div>
                        <h2>Edit {editingItem.albumName || editingItem.productName}</h2>
                        
                        {/* If editing an album */}
                        {editingItem.albumName && (
                            <>
                                <label>
                                    Album Name:
                                    <input
                                        type="text"
                                        name="albumName"
                                        value={editingItem.albumName}
                                        onChange={handleInputChange}
                                    />
                                </label>

                                {/* Edit album options */}
                                {editingItem.albumOptions.map((option, index) => (
                                    <div key={index}>
                                        <h4>Edit Option {index + 1}</h4>
                                        <label>
                                            Size:
                                            <input
                                                type="text"
                                                name="size"
                                                value={option.size}
                                                onChange={(e) => handleOptionChange(e, index, 'albumOptions')}
                                            />
                                        </label>
                                        <label>
                                            Pages:
                                            <input
                                                type="number"
                                                name="pages"
                                                value={option.pages}
                                                onChange={(e) => handleOptionChange(e, index, 'albumOptions')}
                                            />
                                        </label>
                                        <label>
                                            Price:
                                            <input
                                                type="number"
                                                name="price"
                                                value={option.price}
                                                onChange={(e) => handleOptionChange(e, index, 'albumOptions')}
                                            />
                                        </label>
                                        <label>
                                            Cost:
                                            <input
                                                type="number"
                                                name="cost"
                                                value={option.cost}
                                                onChange={(e) => handleOptionChange(e, index, 'albumOptions')}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </>
                        )}

                        {/* If editing a product */}
                        {editingItem.productName && (
                            <>
                                <label>
                                    Product Name:
                                    <input
                                        type="text"
                                        name="productName"
                                        value={editingItem.productName}
                                        onChange={handleInputChange}
                                    />
                                </label>

                                {/* Check if productOptions is empty or undefined */}
                                {(!editingItem.productOptions || editingItem.productOptions.length === 0) && (
                                    <>
                                        <label>
                                            Price:
                                            <input
                                                type="number"
                                                name="price"
                                                value={editingItem.price || ''}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label>
                                            Description:
                                            <textarea
                                                name="description"
                                                value={editingItem.description || ''}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                    </>
                                )}

                                {/* Edit product options if they exist */}
                                {editingItem.productOptions && editingItem.productOptions.map((option, index) => (
                                    <div key={index}>
                                        <h4>Edit Option {index + 1}</h4>
                                        <label>
                                            Size:
                                            <input
                                                type="text"
                                                name="size"
                                                value={option.size}
                                                onChange={(e) => handleOptionChange(e, index, 'productOptions')}
                                            />
                                        </label>
                                        <label>
                                            Price:
                                            <input
                                                type="number"
                                                name="price"
                                                value={option.price}
                                                onChange={(e) => handleOptionChange(e, index, 'productOptions')}
                                            />
                                        </label>
                                        <label>
                                            Cost:
                                            <input
                                                type="number"
                                                name="cost"
                                                value={option.cost}
                                                onChange={(e) => handleOptionChange(e, index, 'productOptions')}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </>
                        )}

                        {/* Image Upload */}
                        <label>
                            Upload Image:
                            <input type="file" onChange={handleImageUpload} />
                        </label>

                        <button onClick={editingItem.albumName ? saveAlbumChanges : saveProductChanges}>
                            Save Changes
                        </button>
                    </div>
                )}
            </Modal>

        </main>
    );
}
