import { useState, useEffect } from "react";
import './FeatureFloat.css'



export default function FeatureFloat(props) {
    const [visible, setVisible] = useState(false);

  // Trigger the animation when the component mounts
  useEffect(() => {
    setTimeout(() => setVisible(true), 100); // Adding a slight delay before showing the h4
  }, []);


    return(
        <div  className={`featureContainer ${props.reverse ? 'reverse' : ''}`} // Apply reverse class if the reverse prop is true
        style={{
          width: '100%',
          maxHeight: '50vh',
          backgroundColor: `${props.backgroundColor}`,
          alignItems: 'center',
        }}
        
        >
            <div className="imageContainer">
                <img src={props.imageUrl}  />
            </div>
            <div className="info"
                style={{
                    transform: visible ? 'translateY(0)' : 'translateY(100%)', // Start below the container
                    opacity: visible ? 1 : 0, // Fade in effect
                    transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out', 
                }}
            >
                <h6 style={{color: `${props.textColor}`, fontWeight: 700, zIndex: 5 }}>{props.title}</h6>
                <p style={{color: `${props.textColor}`,textAlign: "left", zIndex: 5}}>{props.description}</p>
            </div>

        </div>
    )
}