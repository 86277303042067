import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbars/Navbar';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from '../../aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { signUp, confirmSignUp } from 'aws-amplify/auth';
import { useUser} from '../../context/UserProvider';
Amplify.configure(awsExports);

// Custom theme
const theme = {
    name: 'custom-theme',
    tokens: {
      colors: {
        background: {
          primary: '#ffffff',
          secondary: '#f5f5f5',
        },
        brand: {
          primary: {
            10: '#f5f5f5',
            80: '#666666',
            90: '#333333',
            100: '#000000',  // main brand color
          },
        },
        font: {
          interactive: '{colors.brand.primary.100}',
        },
      },
      components: {
        authenticator: {
          router: {
            borderWidth: '0',
            boxShadow: 'none',
          },
          form: {
            padding: '1rem',
          },
        },
      },
    },
  };

export default function Login() {
    const navigate = useNavigate();
    const { refreshUser, cognitoUser, userRole, authStatus } = useUser(); 
    

    useEffect(() => {
        if (authStatus === 'authenticated') {
            navigate('/');
        }
    }, [authStatus, navigate, refreshUser]);

    return (
        <div className="login-container">
            <Navbar />
            <div className="auth-wrapper">
            <Authenticator
                    theme={theme}
                    initialState="signIn"
                    formFields={{
                        signUp: {
                            given_name: {
                                order: 1,
                                label: 'First Name',
                                placeholder: 'Enter your first name',
                                required: true,
                            },
                            family_name: {
                                order: 2,
                                label: 'Last Name',
                                placeholder: 'Enter your last name',
                                required: true,
                            },
                            email: {
                                order: 3,
                                label: 'Email',
                                placeholder: 'Enter your email',
                                required: true,
                            },
                            phone_number: {
                                order: 4,
                                label: 'Phone'
                            },
                            password: {
                                order: 5,
                                label: 'Password'
                            },
                            confirm_password: {
                                order: 6,
                                label: 'Confirm Password'
                            }
                        }
                    }}
                    services={{
                        async handleSignUp(formData) {
                            console.log('form data:', formData)
                            const { username, password, options: {userAttributes} } = formData;
                           
                            
                            const signUpData = {
                                username,
                                password,
                                options: {
                                    userAttributes:{
                                        ...userAttributes,
                                        'custom:role': 'Client'
                                    }
                                }   
                            };
                            return signUp(signUpData);
                        },
                        async handleConfirmSignUp(formData) {
                            const { username, confirmationCode } = formData;
                            try {
                                // First confirm the sign up
                                await confirmSignUp({
                                    username,
                                    confirmationCode
                                });
                                 // Poll for user data
                                let attempts = 0;
                                const maxAttempts = 10;
                                const pollInterval = 1000; // 1 second

                                while (attempts < maxAttempts) {
                                    try {
                                        await refreshUser();
                                        // If refreshUser succeeds, break the loop
                                        break;
                                    } catch (error) {
                                        attempts++;
                                        if (attempts === maxAttempts) {
                                            throw new Error('Failed to retrieve user data after multiple attempts');
                                        }
                                        // Wait before next attempt
                                        await new Promise(resolve => setTimeout(resolve, pollInterval));
                                    }
                                }

                                // Navigate only after successful data retrieval
                                navigate('/');
                            } catch (error) {
                                console.error('Error confirming sign up:', error);
                                throw error;
                            }
                        }
                    }}
                >
                </Authenticator>
            </div>
        </div>
    );
}

