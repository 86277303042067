import { useState, useEffect } from 'react';
import './PreLaunchSale.css';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { GoDotFill, GoDot } from 'react-icons/go';
import axios from 'axios'
import EmptyNav from '../../../components/navbars/EmptyNav';
// Image imports
import Lux from '../../../assets/images/Lux-Specialty-RusticBrass.jpg';
import CorePages from '../../../assets/images/product/pages/Core.jpg';

export default function PreLaunchSalePage() {
  // State
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [albumName, setAlbumName] = useState('Lux');
  const [loading, setLoading] = useState(true); // Added loading state
  const [coverTypes, setCoverTypes] = useState([]); // Added coverTypes state

  // Image navigation
  const handleForwardPhoto = () => {
    setImageIndex((index) => (index === images.length - 1 ? 0 : index + 1));
  };

  const handleRearPhoto = () => {
    setImageIndex((index) => (index === 0 ? images.length - 1 : index - 1));
  };

  // useEffect to fetch album data
  useEffect(() => {
    const getData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(`${import.meta.env.BASE_API}/albums/${albumName}`);
        setData(response.data);
        setImages(response.data.images || []); // Fallback to empty array if no images
        setCoverTypes([...new Set(response.data.coverOptions.map((opt) => opt.coverType))]);
      } catch (err) {
        console.error('Error fetching data:', err.message);
      } finally {
        setLoading(false); // Stop loading after fetch
      }
    };

    if (albumName) {
      getData();
    }
  }, [albumName]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <main>
      <EmptyNav />
      <section className="section" style={{ backgroundColor: '#3c3c3c', width: '100%' }}>
        <div className="text50">
          <div className="newRelease">
            <p style={{ color: '#3c3c3c', margin: 'auto auto', alignItems: 'center' }}>New Release</p>
          </div>
          <div className="textDisplay">
            <h1
              style={{
                color: 'white',
                fontWeight: 600,
                fontSize: '3.75rem',
                textAlign: 'left',
                textShadow: '1px 1px 1px #fff',
              }}
            >
              Introducing the Lux Album
            </h1>
            <h4 style={{ color: 'white', textAlign: 'left' }}>
              Elevate your memories with our premium hand-crafted albums, featuring the flagship 'Lux' collection
            </h4>
            <a>
              <button>Shop Now</button>
            </a>
          </div>
        </div>
        <div className="image50">
            <FaChevronCircleLeft color="#967f71" id="leftArrow" size={25} onClick={handleRearPhoto} />
            {loading ? (
              <p>Loading...</p>
            ) : images.length > 0 ? (
              <img src={images[imageIndex]?.url} alt="Album" style={{ width: '85%' }} />
            ) : (
              <p>No images available</p>
            )}
            <FaChevronCircleRight color="#967f71" id="rightArrow" size={25} onClick={handleForwardPhoto} />
            <div className="imageActiveCircle">
              {images.map((item, key) => (
                <div style={{ display: 'flex' }} key={key} >
                  {imageIndex === key ? (
                    <GoDotFill size={20} color="#967f71" />
                  ) : (
                    <GoDot size={20} color="#967f71" />
                  )}
                </div>
              ))}
            </div>
        </div>
      </section>
      <section className="section" style={{ backgroundColor: '#f5f5f5', width: '100%' }}>
        <div className="image50">
            <FaChevronCircleLeft color="#967f71" id="leftArrow" size={25} onClick={handleRearPhoto} />
            {loading ? (
              <p>Loading...</p>
            ) : images.length > 0 ? (
              <img src={images[imageIndex]?.url} alt="Album" style={{ width: '85%' }} />
            ) : (
              <p>No images available</p>
            )}
            <FaChevronCircleRight color="#967f71" id="rightArrow" size={25} onClick={handleForwardPhoto} />
            <div className="imageActiveCircle">
              {images.map((item, key) => (
                <div style={{ display: 'flex' }} key={key}>
                  {imageIndex === key ? (
                    <GoDotFill size={20} color="#967f71" />
                  ) : (
                    <GoDot size={20} color="#967f71" />
                  )}
                </div>
              ))}
            </div>
        </div>
        <div className="text50">
          <div className="newRelease">
            <p
              style={{
                backgroundColor: '#3c3c3c',
                color: 'white',
                margin: 'auto auto',
                alignItems: 'center',
                borderRadius: '5px',
              }}
            >
              30% Off Sale
            </p>
          </div>
          <div className="textDisplay">
            <h1
              style={{
                color: '#3c3c3c',
                fontWeight: 600,
                fontSize: '3.75rem',
                textAlign: 'left',
                textShadow: '1px 1px 1px #fff',
              }}
            >
              Premium Albums Crafted with Care
            </h1>
            <h4 style={{ color: '#3c3c3c', textAlign: 'left' }}>
              Discover our exquisite collection of handcrafted albums, perfect for preserving your most cherished memories
            </h4>
            <a>
              <button>Shop Now</button>
            </a>
          </div>
        </div>
      </section>
      <section className="section" style={{ backgroundColor: '#f5f5f5', width: '100%' }}>
        <div className="text50">
          <div className="newRelease">
            <p
              style={{
                backgroundColor: '#3c3c3c',
                color: 'white',
                margin: 'auto auto',
                alignItems: 'center',
                borderRadius: '5px',
              }}
            >
              30% Off Sale
            </p>
          </div>
          <div className="textDisplay">
            <h1
              style={{
                color: '#3c3c3c',
                fontWeight: 600,
                fontSize: '3.75rem',
                textAlign: 'left',
                textShadow: '1px 1px 1px #fff',
              }}
            >
              Elevate Your Memories
            </h1>
            <h4 style={{ color: '#3c3c3c', textAlign: 'left' }}>
              Our albums are designed to capture the essence of your cherished moments, with meticulous attention to detail
              and quality craftsmanship
            </h4>
            <a>
              <button style={{ margin: '10px' }}>Explore the collection</button>
              <button>Request a Call-back</button>
            </a>
          </div>
        </div>
        <div className="image50">
            <FaChevronCircleLeft color="#967f71" id="leftArrow" size={25} onClick={handleRearPhoto} />
            {loading ? (
              <p>Loading...</p>
            ) : images.length > 0 ? (
              <img src={images[imageIndex]?.url} alt="Album" style={{ width: '85%' }} />
            ) : (
              <p>No images available</p>
            )}
            <FaChevronCircleRight color="#967f71" id="rightArrow" size={25} onClick={handleForwardPhoto} />
            <div className="imageActiveCircle">
              {images.map((item, key) => (
                <div style={{ display: 'flex' }} key={key}>
                  {imageIndex === key ? (
                    <GoDotFill size={20} color="#967f71" />
                  ) : (
                    <GoDot size={20} color="#967f71" />
                  )}
                </div>
              ))}
            </div>
        </div>
      </section>
    </main>
  );
}
