import { useState } from "react";
import './AddressForm.css';

export default function AddressForm({ onSubmit }) {
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    const stateAbbreviations = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", 
        "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", 
        "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", 
        "WV", "WI", "WY"
    ];

    const handleSubmit = (e) => {
        e.preventDefault();

        // Prepare the new address data
        const newAddress = { street, city, state, zip };

        // Call the parent onSubmit function and pass the address data
        onSubmit(newAddress);
    };

    return (
        <div className="addressForm">
            <form onSubmit={handleSubmit}>
                <label>Street</label>
                <input 
                    type='text'
                    name='street'
                    placeholder='Street'
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    required
                />
                
                <label>City</label>
                <input 
                    type='text'
                    name='city'
                    placeholder='City'
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                />

                <label>State</label>
                <select 
                    name='state' 
                    value={state} 
                    onChange={(e) => setState(e.target.value)} 
                    required
                >
                    <option value="" disabled>State</option>
                    {stateAbbreviations.map((abbr) => (
                        <option key={abbr} value={abbr}>
                            {abbr}
                        </option>
                    ))}
                </select>

                <label>Zip Code</label>
                <input 
                    type='text'
                    name='zip'
                    placeholder='Zip Code'
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    required
                />

                <button type="submit">Submit Address</button>
            </form>
        </div>
    );
}
