import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import './MyOrdersPage.css';
import ReactLoading from 'react-loading';
import axios from 'axios';
import { UserContext } from '../../context/UserProvider';
import Navbar from "../../components/navbars/Navbar";
import { useUser } from "../../context/UserProvider";
import { fetchAuthSession } from "aws-amplify/auth";

export default function MyOrdersPage() {
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { mongoUser, cognitoUser } = useUser();
    // STATE ***********************************
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noOrders, setNoOrders] = useState('');
    const [expand, setExpand] = useState({});
    const [uploadedImages, setUploadedImages] = useState({});
    const [msg, setMsg] = useState('');
    const [uploadedStatus, setUploadedStatus] = useState({});
    const [albumFiles, setAlbumFiles] = useState({});
    const [productFiles, setProductFiles] = useState({});
    const [recentOrderId, setRecentOrderId] = useState(null);
    const [imagesCompleted, setImagesCompleted] = useState(false);
    const [isReadyForEditing, setIsReadyForEditing] = useState({});
    


    // FUNCTIONS **************** FUNCTIONS ************* FUNCTIONS ********************

    // Helper function to check if all images have been uploaded for both albums and products
    const checkIfAllImagesUploaded = (order) => {
        const allAlbumsUploaded = order.albums.every(album => uploadedStatus[album._id]);
        const allProductsUploaded = order.products.every(product => uploadedStatus[product._id]);
        return allAlbumsUploaded && allProductsUploaded;
    };

    // Function to send status update to backend
    const updateOrderStatus = async (orderId, orderIndex) => {
        try {
            const order = data[orderIndex];
    
            // Check if the order has an album or a product with productName 'Editing'
            const hasAlbum = order?.albums && Array.isArray(order.albums) && order.albums.length > 0;
            const hasEditingProduct = order?.products && Array.isArray(order.products) && order.products.some(product => product.productName === 'Editing');
            
            // Determine status based on conditions
            let status;
            if (hasAlbum || hasEditingProduct) {
                status = 'Sent to editing team';
            } else {
                status = 'Send to manufacturing';
            }
    
            await axios.put(`https://9equ2m7eha.execute-api.us-east-1.amazonaws.com/dev/orders/${mongoUser._id}/${orderId}`, {
                status: status,
                readyForEditing: hasAlbum || hasEditingProduct,
                user: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                },
            });
            console.log(`Order ${orderId} status updated to "${status}" successfully.`);
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };
    

    // Handle file selection and upload for albums
    const handleAlbumFileChange = async (albumId, albumName, albumSize, albumPages, index, e) => {
        const files = e.target.files;
        const maxImagesAllowed = 2 * albumPages;

        if ((albumFiles[albumId] || 0) + files.length > maxImagesAllowed) {
            alert(`You can upload up to ${maxImagesAllowed} images for this album.`);
            return;
        }

        const formData = new FormData();
        formData.append('mongoUser._id', mongoUser._id);
        formData.append('orderId', data[index]._id);
        formData.append('albumId', albumId);
        formData.append('albumName', albumName);
        formData.append('albumSize', albumSize);
        formData.append('albumPages', albumPages);
        formData.append('index', index)

        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }

        setMsg('Uploading...');

        try {
            await axios.post(`http://localhost:9000/gallery/gallery-images/${mongoUser._id}/${data[index]._id}`, formData, {
                headers: { "Content-Type": 'multipart/form-data' }
            });

            setMsg('Upload successful');
            setUploadedImages((prev) => ({
                ...prev,
                [albumId]: URL.createObjectURL(files[0]),
            }));
            setUploadedStatus((prevStatus) => ({ ...prevStatus, [albumId]: true }));

            const newImageCount = albumFiles[albumId] + files.length;
            setAlbumFiles((prev) => ({ ...prev, [albumId]: newImageCount }));

            if (checkIfAllImagesUploaded(data[index])) {
                updateOrderStatus(data[index]._id, index);
            }
        } catch (error) {
            console.error('Error uploading album files:', error);
            setMsg('Upload failed');
        }
    };

    // Handle file selection and upload for products
    const handleProductFileChange = async (productId, productName, productSize, index, e) => {
        const files = e.target.files;

        if (files.length > 1) {
            alert('Please select only one file.');
            return;
        }

        const formData = new FormData();
        formData.append('mongoUser._id', mongoUser._id);
        formData.append('orderId', data[index]._id);
        formData.append('productId', productId);
        formData.append('productName', productName);
        formData.append('productSize', productSize);
        formData.append('index', index)
        formData.append('images', files[0]);

        setMsg('Uploading...');

        try {
            await axios.post(`http://localhost:9000/gallery/gallery-images/${mongoUser._id}/${data[index]._id}`, formData, {
                headers: { "Content-Type": 'multipart/form-data' }
            });

            setMsg('Upload successful');
            setUploadedImages((prev) => ({
                ...prev,
                [productId]: URL.createObjectURL(files[0]),
            }));
            setUploadedStatus((prevStatus) => ({ ...prevStatus, [productId]: true }));

            setProductFiles((prev) => ({ ...prev, [productId]: 1 }));

            if (checkIfAllImagesUploaded(data[index])) {
                updateOrderStatus(data[index]._id, true);
            }
        } catch (error) {
            console.error('Error uploading product image:', error);
            setMsg('Upload failed');
        }
    };

    // Trigger file input for albums
    const triggerAlbumInput = (albumId) => {
        document.getElementById(`albumFileInput-${albumId}`).click();
    };

    // Trigger file input for products
    const triggerProductInput = (productId) => {
        document.getElementById(`productFileInput-${productId}`).click();
    };

    // Expand and collapse order items
    const toggleExpand = (orderId) => {
        setExpand((prevState) => ({
            ...prevState,
            [orderId]: !prevState[orderId],
        }));
    };

    // Ensure minimum image upload completion
    const imageUploadComplete = (orderId, albums, products) => {
        const allAlbumsHaveMinImages = albums.every(album => (albumFiles[album._id] || 0) >= album.albumOptions.pages);
        const allProductsUploaded = products.every(product => uploadedStatus[product._id]);
    
        if (!allAlbumsHaveMinImages) {
            alert('Please upload at least the minimum required images for all albums.');
            return;
        }
    
        if (!allProductsUploaded) {
            alert('Please upload images for all products.');
            return;
        }
    
        // Mark this specific order as ready for editing
        setIsReadyForEditing(prevState => ({
            ...prevState,
            [orderId]: true  // Only update readiness for this order
        }));
    
        // Update the backend status for this specific order
        updateOrderStatus(orderId, true);
    };
    

    // Fetch orders on component load
    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            try {
                const session = await fetchAuthSession()
                const idToken = session.tokens?.idToken?.toString();
                const response = await axios.get(
                    `https://9equ2m7eha.execute-api.us-east-1.amazonaws.com/dev/orders/${mongoUser._id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    }
                );                if (response.data.length === 0) {
                    setNoOrders("You don't have any orders yet.");
                } else {
                console.log("response data:", response.data)
                    setData(response.data);
                }
            } catch (err) {
                console.log("Error fetching orders:", err);
            }
            setLoading(false);
        };
        getData();
    }, [mongoUser._id]);

    useEffect(() => {
        if (!mongoUser) {
            navigate('/login');
            return
        } else {
            const orderIdFromStripe = searchParams.get('orderId');
            if (orderIdFromStripe) {
                setExpand((prevState) => ({ ...prevState, [orderIdFromStripe]: true }));
                setRecentOrderId(orderIdFromStripe);
            }
        }
    }, [mongoUser, navigate, searchParams]);

    useEffect(() => {
        // Only call updateOrderStatus if the imagesCompleted is true and isReadyForEditing is an object with a valid orderId
        if (imagesCompleted && isReadyForEditing && Object.keys(isReadyForEditing).length > 0) {
            const orderId = Object.keys(isReadyForEditing)[0]; // Extract the orderId from isReadyForEditing
            updateOrderStatus(orderId, true);
        }
    }, [isReadyForEditing, imagesCompleted]);
    

    // Render body of page
    return (
        <main>
            <Navbar />
            <h1 style={{ marginTop: '50px' }}>My Orders</h1>
            {loading? <ReactLoading  type='spin' size={55} color='royalblue' />
            :
            
            <div className="orders">
                {loading ? (
                    <ReactLoading type="bubbles" color="#000" />
                ) : noOrders ? (
                    <p style={{ color: 'royalblue' }}>{noOrders}</p>
                ) : (
                    data.map((item, index) => (
                        <div key={index} className="order-box">
                            <h2>Order Details</h2>
                            <div className="order-summary">
                                <div className="order-info">
                                    <p style={{color: '#3c3c3c'}}><span className="label">Order Number: </span>{item._id}</p>
                                    <p style={{color: '#3c3c3c'}}><span className="label">Date Ordered: </span>{new Date(item.createdAt).toDateString()}</p>
                                    <p style={{color: '#3c3c3c'}}><span className="label">Total: </span>${item.totalPrice}</p>
                                    <p style={{color: '#3c3c3c'}}><span className="label">Status: </span>{item.statusHistory?.[item.statusHistory.length - 1]?.status || "Awaiting user images"}</p>
                                </div>
                                <div className="shipping-address">
                                    <p><span className="label">Shipping Address: </span>{item.shippingAddress.street}, {item.shippingAddress.city}, {item.shippingAddress.state} {item.shippingAddress.zip}</p>
                                </div>
                            </div>
                            <button onClick={() => toggleExpand(item._id)} className="button1">
                                {expand[item._id] ? "Hide" : "Show"} Order Items
                            </button>
                            {expand[item._id] && (
                                <div className="order-items">
                                    {/* ALBUMS */}
                                    {Array.isArray(item.albums) && item.albums.length > 0 && 
                                        item.albums.map((album, albumIndex) => (
                                            <div key={albumIndex} className="item">
                                                <img src={album.navbarImage} alt={album.albumName} className="item-image" />
                                                <div className="item-details">
                                                    <p className="item-name">Album: {album.albumName} - {album.albumOptions?.size}, {album.albumOptions?.pages} Pages</p>
                                                    <p className="item-price">{item.currency} {album.albumOptions?.price}</p>
                                                </div>
                                                <div className="upload-section">
                                                    <p style={{ color: 'dodgerblue' }}>
                                                        Images Uploaded: {albumFiles[album._id] || 0} / {2 * album.albumOptions?.pages}
                                                    </p>
                                                    {uploadedStatus[album._id] ? (
                                                        <>
                                                            <p style={{ color: 'darkorchid', cursor: 'pointer' }} onClick={() => triggerAlbumInput(album._id)}>
                                                                Add more images (max: {2 * album.albumOptions.pages})
                                                            </p>
                                                            <input
                                                                type="file"
                                                                id={`albumFileInput-${album._id}`}
                                                                accept="image/*"
                                                                multiple
                                                                hidden
                                                                onChange={(e) => handleAlbumFileChange(album._id, album.albumName, album.albumOptions.size, album.albumOptions.pages, index, e)}
                                                                />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <input
                                                                type="file"
                                                                id={`albumFileInput-${album._id}`}
                                                                accept="image/*"
                                                                multiple
                                                                hidden
                                                                onChange={(e) => handleAlbumFileChange(album._id, album.albumName, album.albumOptions.size, album.albumOptions.pages, index, e)}
                                                                />
                                                            <button onClick={() => triggerAlbumInput(album._id)} className={`uploadImagesButton button1 ${recentOrderId === item._id ? 'highlight-button' : ''}`}>
                                                                Upload Images
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {/* PRODUCTS */}
                                    {Array.isArray(item.products) && item.products.length > 0 && 
                                        item.products.map((product, productIndex) => (
                                            <div key={productIndex} className="item">
                                                <img src={product.navbarImage} alt={product.productName} className="item-image" />
                                                <div className="item-details">
                                                    <p className="item-name">Product: {product.productName} - {product.productOptions.size}</p>
                                                    <p className="item-price">{item.currency} {product.productOptions.price}</p>
                                                </div>
                                                <div className="upload-section">
                                                    <p style={{ color: 'dodgerblue' }}>
                                                        Image Uploaded: {productFiles[product._id] || 0} / 1
                                                    </p>
                                                    {uploadedStatus[product._id] ? (
                                                        <>
                                                            <p style={{ color: 'darkorchid', cursor: 'pointer' }} onClick={() => triggerProductInput(product._id)}>
                                                                Add more images (max: 1)
                                                            </p>
                                                            <input
                                                                type="file"
                                                                id={`productFileInput-${product._id}`}
                                                                accept="image/*"
                                                                hidden
                                                                onChange={(e) => handleProductFileChange(product._id, product.productName, product.productOptions.size, index, e)}
                                                                />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <input
                                                                type="file"
                                                                id={`productFileInput-${product._id}`}
                                                                accept="image/*"
                                                                hidden
                                                                onChange={(e) => handleProductFileChange(product._id, product.productName, product.productOptions.size, index, e)}
                                                                />
                                                            <button onClick={() => triggerProductInput(product._id)} className={`uploadImagesButton button1 ${recentOrderId === item._id ? 'highlight-button' : ''}`}>
                                                                Upload Image
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {/* Complete Image Upload Button */}
                                    <button 
                                        onClick={() => imageUploadComplete(item._id, item.albums, item.products)} 
                                        style={{ backgroundImage: 'linear-gradient(to right, darkblue, darkorchid)' }}
                                    >
                                        {isReadyForEditing[item._id] ? "Upload more images" : "Press when image upload is complete"}
                                    </button>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
            }
        </main>
    );
}
