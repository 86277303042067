import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Navbar from '../../components/navbars/Navbar';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { signUp, confirmSignUp } from 'aws-amplify/auth';
import './createAccount.css';

const PASSWORD_REGEX = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])(?=.*[0-9].*[0-9])(?=.*[!@#$%^&*].*[!@#$%^&*]).{12,32}$/;

export default function CreateAccount() {
  const navigate = useNavigate();
  const firstRef = useRef();
  const errRef = useRef();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    verify: ''
  });

  const [eye, setEye] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    firstRef.current.focus();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      setFormData(prev => ({
        ...prev,
        [name]: value.replace(/\D/g, '').slice(0, 10)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validatePassword = (password) => {
    return PASSWORD_REGEX.test(password);
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors('');

    try {
      console.log('Submitting signup form...');

      if (!validatePassword(formData.password)) {
        setErrors('Password must contain 12+ characters, 2 uppercase, 2 lowercase, 2 numbers, and 2 special characters');
        setIsLoading(false);
        return;
      }

      if (formData.password !== formData.verify) {
        setErrors('Passwords do not match');
        setIsLoading(false);
        return;
      }

      const { isSignUpComplete, userId } = await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            email: formData.email,
            phone_number: `+1${formData.phone}`,
            given_name: formData.firstName,
            family_name: formData.lastName,
          }
        }
      });

      console.log('Signup successful:', { isSignUpComplete, userId });
      setModalOpen(true);
    } catch (error) {
      console.error('Signup error:', error);
      setErrors(error.message || 'Error creating account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await confirmSignUp({
        username: formData.email,
        confirmationCode: verificationCode
      });
      console.log('Verification successful');
      navigate('/login');
    } catch (error) {
      console.error('Verification error:', error);
      setErrors(error.message || 'Verification failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEye = () => setEye(prev => !prev);

  return (
    <main>
      <Navbar />
      <div className='formBox'>
        <h1>Create Your Account</h1>
        
        {errors && <p ref={errRef} className="error-message" aria-live='assertive'>{errors}</p>}
        
        <form onSubmit={handleSignupSubmit} className='formBox'>
          <input
            type='text'
            name='firstName'
            placeholder='First Name'
            value={formData.firstName}
            onChange={handleChange}
            required
            tabIndex={1}
            ref={firstRef}
            autoComplete='given-name'
            className='formBox'
          />
          
          <input
            type='text'
            name='lastName'
            placeholder='Last Name'
            value={formData.lastName}
            onChange={handleChange}
            required
            tabIndex={2}
            autoComplete='family-name'
            className='formBox'
          />

          <input
            type='tel'
            name='phone'
            placeholder='Phone Number'
            value={formData.phone}
            onChange={handleChange}
            required
            tabIndex={3}
            autoComplete='tel'
            className='formBox'
          />

          <input
            type='email'
            name='email'
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
            required
            tabIndex={4}
            autoComplete='email'
            className='formBox'
          />

          <div className="password-input-container">
            <input
              type={eye ? 'text' : 'password'}
              name='password'
              placeholder='Password'
              value={formData.password}
              onChange={handleChange}
              required
              tabIndex={5}
              autoComplete='new-password'
              className='formBox'
            />
            <span className="password-toggle">
              {eye ? 
                <IoMdEye onClick={handleEye}/> : 
                <IoMdEyeOff onClick={handleEye}/>
              }
            </span>
          </div>

          <input
            type={eye ? 'text' : 'password'}
            name='verify'
            placeholder='Verify your password'
            value={formData.verify}
            onChange={handleChange}
            required
            tabIndex={6}
            autoComplete='new-password'
            className='formBox'
          />

          <button 
            className='button1' 
            type="submit"
            disabled={isLoading || 
              !formData.firstName || 
              !formData.lastName || 
              !formData.phone || 
              !formData.email || 
              !formData.password || 
              !formData.verify}
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
        </form>

        <br/>
        <a href='/login'>Already have an account? Login</a>
      </div>

      <Modal 
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="verification-modal"
      >
        <form onSubmit={handleVerificationSubmit}>
          <h2>Enter Verification Code</h2>
          <p>Please enter the verification code sent to your email</p>
          <input
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            className='formBox'
          />
          <button 
            type="submit"
            disabled={isLoading || !verificationCode}
            className='button1'
          >
            {isLoading ? 'Verifying...' : 'Submit Verification Code'}
          </button>
        </form>
      </Modal>
    </main>
  );
}
