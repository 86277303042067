import { useState } from "react";
import './productCard.css'
import { FaRegImage } from "react-icons/fa";

export default function ProductCard(props) {

    return (
      <div className="productCard">
        <h2>{props.name} {props.size} - {props.pages}pg.</h2>
        <h4>{props.category}</h4>
        <div className="imageArea" style={{width: '100%'}}>
            {!props.image ? <FaRegImage size={25}/> 
            
            :
            <img src={props.image} style={{width: '100%', height: '100%',objectFit: "cover"}}/>
            }
        </div>
        <div className="infoArea">
            <h6><strong>Price: ${props.price}</strong></h6>
            <h6>Cost: ${props.cost}</h6>
        </div>
        <div style={{diplay: 'flex', marginBottom: '5px'}}>
            <button onClick={() => props.handleEdit(props.item)} style={{borderRadius: '15px'}}>Edit Item</button>
        </div>

      </div>
);
}