import { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import './cartPage.css';
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "../../components/navbars/Navbar";
import CartItem from "../../components/cartItems/CartItem";
import { CartContext } from '../../context/CartContext'; 
import { UserContext } from "../../context/UserProvider";
import CheckoutModal from '../../components/modals/checkoutModal';
import AddressForm from "../../components/forms/AddressForm";
import { useUser } from "../../context/UserProvider";

// Load your Stripe publishable key
const stripePromise = loadStripe("pk_test_51PwkKlENk4G5kOXSNMKS1Ps3lDzjtP1fGuRScfsNi1G0fXtX9SZvmzWQ5YNKnyrGlMj8gQtBCApR59zof6zzxpCV004vQPqwE4");

export default function CartPage() {
    const { cartItems } = useContext(CartContext); 
    const { cognitoUser, mongoUser } = useUser()
    const [checkoutActive, setCheckoutActive] = useState(false);
    const [subTotal, setSubTotal] = useState(0);
    const [tax, setTax] = useState(null); 
    const [shipping, setShipping] = useState(0);
    const [total, setTotal] = useState(0); 
    const [clientSecret, setClientSecret] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [userAddress, setUserAddress] = useState(null);  
    const [addingNewAddress, setAddingNewAddress] = useState(false); 
    const navigate = useNavigate();

    // Calculate subtotal
    function calculateSubTotal(items) {
        const subTotal = items.reduce((acc, item) => acc + (Number(item.price) * item.quantity || 0), 0);
        setSubTotal(subTotal.toFixed(2));
    }

    // Calculate total
    function calculateTotal(subTotal, shipping, tax) {
        const taxValue = tax? Number(tax) : 0;
        const total = (Number(subTotal) + Number(shipping) + Number(taxValue)).toFixed(2);
        setTotal(total);
    }

    // Handle checkout process
    async function handleCheckout() {
        try {
            console.log(cartItems)
            if (!cognitoUser) {
                alert('Please log in to proceed with checkout.');
                navigate('/login');
                return;
            }

            // Fetch user's shipping address
            const findUser = await axios.get('http://localhost:9000/auth/find-user', {
                params: { _id: user._id }
            });
            const userData = findUser.data;
            const shippingAddress = userData?.shippingAddress;

            if (!shippingAddress) {
                alert('No shipping address found. Please add an address to your profile.');
                setAddingNewAddress(true);  
                setShowModal(true);
                return;
            }

            // Show modal to confirm shipping address
            setUserAddress(shippingAddress);
            setShowModal(true);
        } catch (error) {
            console.error('Error during checkout:', error.response ? error.response.data : error);
        }
    }

    // Proceed with order after confirming address
    async function handleProceedWithOrder(selectedAddress) {
        try {
            console.log('Proceeding with order for address:', selectedAddress);
    
            // Close the modal
            setShowModal(false);
    
            // Create a new order with the selected address
            const newOrderResponse = await axios.post(`${import.meta.env.BASE_API}/orders/new-order`, {
                cartItems: cartItems.map(item => ({
                    albumName: item.albumName,
                    productName: item.productName,
                    albumOptions: item.albumOptions,
                    productOptions: item.productOptions,
                    coverOptions: item.coverSelection,
                    quantity: item.quantity
                })),
                userId: mongoUser._id,
                firstName: mongoUser.firstName,
                lastName: mongoUser.lastName,
                shippingAddress: selectedAddress || userAddress,  
                paymentStatus: 'pending'
            });
    
            const { orderId } = newOrderResponse.data;
            console.log('Order created with ID:', orderId);
    
            // Create Stripe Checkout session and pass the orderId
            const response = await axios.post(`${import.meta.env.BASE_API}/stripe/create-checkout-session`, {
                cartItems,
                orderId,
                user
            });
    
            const { sessionId } = response.data;
            console.log('Stripe session ID:', sessionId);
    
            // Redirect to Stripe Checkout
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId });
    
            if (error) {
                console.error('Error in Stripe Checkout redirection:', error.message);
            }
        } catch (error) {
            // Handle the error from the backend
            console.error('Error proceeding with order:', error.response ? error.response.data.error : error);
            alert(error.response?.data?.error || 'Something went wrong while placing the order.');
        }
    }
    

    // Handle new address submission from AddressForm component
    const handleNewAddressSubmit = async (addressData) => {
        try {
            console.log('New address submitted:', addressData);

            // Set the new address in state
            setUserAddress(addressData);

            // Immediately proceed with the order and close the modal
            await handleProceedWithOrder(addressData);
        } catch (error) {
            console.error('Error submitting new address:', error);
        }
    };

    // Calculate subtotal when cartItems change
    useEffect(() => {
        calculateSubTotal(cartItems);
        console.log(cartItems)
    }, [cartItems]);

    // Update total when subtotal, shipping, or tax changes
    useEffect(() => {
        calculateTotal(subTotal, shipping, tax);
    }, [subTotal, shipping, tax]);

    return (
        <main>
            <Navbar />
            <div className="container">
                <div className="cartItemArea">
                    {cartItems.length === 0 ? (
                        <h1>Your Cart is Empty</h1>
                    ) : (
                        cartItems.map((item, index) => (
                            <CartItem
                                key={index}
                                id={item.albumOptions?._id || item.productOptions?._id}
                                albumName={item.albumName || item.productName || 'Product'}
                                productNumber={item._id || index}
                                price={item.price}
                                size={item.size}
                                image={item.imageUrl}
                                quantity={item.quantity || 1}
                                pages={item.pages}
                                coverImage={item.coverSelection?.url}
                                coverType={item.coverSelection?.coverType}
                                coverName={item.coverSelection?.coverName}
                                albumOptions={item.albumOptions}
                            />
                        ))
                    )}
                </div>

                {cartItems.length > 0 && (
                    <div className="chargeArea">
                        <div className="costArea">
                            <div className="charges">
                                <h4>Subtotal:</h4>
                                <h4>${subTotal}</h4>
                            </div>
                            <div className="charges">
                                <h4>Shipping:</h4>
                                <h4 style={{ color: '#3f9a59' }}>
                                    {shipping === 0 ? 'Free' : `$${shipping}`}
                                </h4>
                            </div>
                            <div className="charges">
                                <h4>Tax:</h4>
                                {tax !== null ? (
                                    <h4>${tax.toFixed(2)}</h4>
                                ) : (
                                    <h6>Calculated at checkout</h6>
                                )}
                            </div>
                            <div>
                                <p style={{color: '#242424'}}>___________________________</p>
                            </div>
                            <div className="charges">
                                <h4>Total:</h4>
                                <h4>${total} + tax</h4>
                            </div>
                        </div>

                        <div className="checkoutButtons">
                            <button onClick={handleCheckout} style={{ backgroundColor: 'royalblue'}}>Begin Checkout</button>
                        </div>
                    </div>
                )}

                {/* Modal with shipping address confirmation or address form */}
                {showModal && (
                    <CheckoutModal show={showModal} onClose={() => setShowModal(false)}>
                        <div className="modal-content">
                            {!addingNewAddress ? (
                                <>
                                    <h3>Confirm Shipping Address</h3>
                                    <h6 style={{color: '#3c3c3c', textAlign: 'center'}}>{userAddress?.street}</h6>
                                    <h6 style={{color: '#3c3c3c', textAlign: 'center'}}>{userAddress?.city}, {userAddress?.state} {userAddress?.zip}</h6>
                                    
                                    <button style={{margin: '10px auto', backgroundColor: 'royalblue'}} onClick={() => handleProceedWithOrder(userAddress)}>Yes, use this address</button>
                                    <button style={{margin: '10px auto', backgroundColor: 'royalblue'}} onClick={() => setAddingNewAddress(true)}>No, I want to change it</button>
                                </>
                            ) : (
                                <AddressForm onSubmit={handleNewAddressSubmit} />
                            )}
                        </div>
                    </CheckoutModal>
                )}
            </div>
        </main>
    );
}
