import React, { useState, useContext, useEffect } from 'react';
import './addProductPage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../context/UserProvider';
import { useUser } from "../../../context/UserProvider";


export default function AddProductPage() {
const navigate = useNavigate()
const {cognitoUser, mongoUser } = useUser()

  const [productName, setProductName] = useState('');
  const [categorySelection, setCategorySelection] = useState('');
  const [availableForPurchase, setAvailableForPurchase] = useState(true);
  const [productNumber, setProductNumber] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [description, setDescription] = useState('');
  const [shippingWeight, setShippingWeight] = useState('');
  const [shippingDimensions, setShippingDimensions] = useState({ length: '', width: '', height: '' });
  const [stripeProductId, setStripeProductId] = useState('');
  const [stripePriceId, setStripePriceId] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [coverOptions, setCoverOptions] = useState([]);
  const [size, setSize] = useState('');
  const [price, setPrice] = useState('');
  const [cost, setCost] = useState('');
  const [priceId, setPriceId] = useState('');
  const [productId, setProductId] = useState('');
  const [coverType, setCoverType] = useState('');
  const [coverName, setCoverName] = useState('');
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [coverPhotos, setCoverPhotos] = useState([]);
  const [marketingFeatures, setMarketingFeatures] = useState(['']); // Initially one empty feature
  const [basePrice, setBasePrice] = useState(0)
  const [submitText, setSubmitText] = useState(``)
  const [submitted, setSubmitted] = useState(false)
  const category = [
    'Albums', 'Prints', 'Image-Box', 'Editing', 'Frames', 'Canvas', 
    'Metal-Art', 'Acrylic-Blocks', 'Edge-Prints', 'Acrylic-Art'
  ];

  const productSize = [
    '4x6', '4x7', '4x8', '4x9', '4x10', '4x12', '5x5', '5x7', '5x8', 
    '5x10', '5x30', '5½x7', '5½x14', '6x6', '6x8', '6x9', '6x18', 
    '7x7', '7x10', '7x11', '8x8', '8x10', '8x12', '8x16', '8x20', 
    '8x24', '8½x11', '9x12', '10x10', '10x13', '10x14', '10x15', 
    '10x16', '10x20', '10x26', '10x30', '11x14', '11x16', '11x22', 
    '11x28', '12x12', '12x16', '12x18', '12x24', '12x36', '13x20', 
    '14x20', '14x21', '14x22', '15x20', '15x24', '15x30', '16x16', 
    '16x20', '16x24', '16x48', '20x20', '20x24', '20x30', '24x24', 
    '24x30', '24x36', '30x30', '30x40', '40x50*', '40x60*'
  ];

  // Function to add product options
  function handleAddProductOption() {
    const newOption = {
      size,
      price,
      cost,
      priceId,
      productId,
    };
    setProductOptions([...productOptions, newOption]);
    setSize('');
    setPrice('');
    setCost('');
    setPriceId('');
    setProductId('');
  }

  // Function to add cover options
  function handleAddCoverOption() {
    const newCoverOption = {
      coverType,
      coverName,
    };
    setCoverOptions([...coverOptions, newCoverOption]);
    setCoverType('');
    setCoverName('');
  }

  // Function to add a new marketing feature
  const handleAddMarketingFeature = () => {
    setMarketingFeatures([...marketingFeatures, '']); // Add a new empty string for each new feature
  };

  // Function to handle input changes for marketing features
  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...marketingFeatures];
    updatedFeatures[index] = value;
    setMarketingFeatures(updatedFeatures);
  };

  // Submit form handler
  function handleSubmit(e) {
    e.preventDefault();
    
    if (!categorySelection) {
      console.error('Category is not selected');
      return; // Stop form submission if no category is selected
    }
  
    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('category', categorySelection);
    formData.append('availableForPurchase', availableForPurchase);
    formData.append('productNumber', productNumber);
    formData.append('currency', currency);
    formData.append('description', description);
    formData.append('stripeProductId', stripeProductId);
    formData.append('stripePriceId', stripePriceId);
    formData.append('shippingWeight', shippingWeight);
    formData.append('shippingDimensions', JSON.stringify(shippingDimensions));
    formData.append('mainImage', mainImage);
    formData.append('productOptions', JSON.stringify(productOptions));
    formData.append('coverOptions', JSON.stringify(coverOptions));
    formData.append('marketing_features', JSON.stringify(marketingFeatures));
    console.log("marketing_features", marketingFeatures)
    // Append basePrice only if no product options exist
    if (productOptions.length === 0) {
      formData.append('basePrice', parseFloat(basePrice));
    }
  
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    for (let i = 0; i < coverPhotos.length; i++) {
      formData.append('coverPhotos', coverPhotos[i]);
    }
  
    console.log(formData); // Log the formData to ensure correct population
  
    axios.post(`${import.meta.env.BASE_API}/products/add-new-product`, formData)
      .then((response) => {
        // Handle successful response
        console.log('Product successfully added');
      
      // Reset all form fields after successful submission
      setProductName('');
      setCategorySelection('');
      setAvailableForPurchase(true);
      setProductNumber('');
      setCurrency('USD');
      setDescription('');
      setShippingWeight('');
      setShippingDimensions({ length: '', width: '', height: '' });
      setStripeProductId('');
      setStripePriceId('');
      setProductOptions([]);
      setCoverOptions([]);
      setSize('');
      setPrice('');
      setCost('');
      setPriceId('');
      setProductId('');
      setCoverType('');
      setCoverName('');
      setImages([]);
      setMainImage(null);
      setCoverPhotos([]);
      setMarketingFeatures(['']); // Reset to initial state
      setBasePrice(0);

      // Optional: If you want to display some confirmation to the user
      setSubmitText(`${productName} has been submitted successfully.`);
      setSubmitted(true);
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  }
  useEffect(() => {
    if(!cognitoUser){
      navigate('/login')
      return
    }
  },[cognitoUser])
  

  return (
    <main>
      <h1>Add Product Page</h1>
      <form onSubmit={handleSubmit} style={{ width: '75%' }}>
        {submitted && <h4 style={{color: 'dodgerblue'}}>{submitText}</h4>}
        {/* Column 1: Basic Info */}
        <div className='columnsArea'>
          <div className='column'>
            <h3>Item Information</h3>
            <input
              type="text"
              placeholder="Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <label style={{padding: '0'}}>Base Price if no options</label>
            <input
              type="number"
              placeholder="Base Price if no options"
              value={basePrice}
              onChange={(e) => setBasePrice(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <select
              value={categorySelection}
              onChange={(e) => setCategorySelection(e.target.value)}
              style={{ width: '100%', margin: '10px auto', padding: '5px', borderRadius: '5px' }}
            >
              <option value="">Select Category</option>
              {category.map((item, categoryIndex) => (
                <option value={item} key={categoryIndex}>
                  {item}
                </option>
              ))}
            </select>
             <div style={{display: 'flex', alignItems: 'center'}}>
              <label style={{width: '40%'}}>Product Images</label>
              <input
                type="file"
                multiple
                onChange={(e) => setImages(e.target.files)}
                style={{ width: '75%', margin: '5px auto' }}
                />
              </div>   
            <div style={{justifyContent: 'space-between'}}>
              <label style={{width: '25%'}}>Main Image</label>
              <input
                type="file"
                onChange={(e) => setMainImage(e.target.files[0])}
                style={{ width: '70%', margin: '5px auto' }}
                />
            </div>
            <textarea 
              
              value={description}
              name='description'
              placeholder='Description'
              onChange={(e) => setDescription(e.target.value)}
              style={{ width: '100%', marginBottom: '10px', overflow: 'scroll', textWrap: 'wrap' }}
            ></textarea>
          </div>

          {/* Column 2: Shipping Information */}
          <div className='column'>
            <h3>Shipping Information</h3>
            <input
              type="text"
              placeholder="Shipping Weight"
              value={shippingWeight}
              onChange={(e) => setShippingWeight(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <input
              type="number"
              placeholder="Length"
              value={shippingDimensions.length}
              onChange={(e) =>
                setShippingDimensions({ ...shippingDimensions, length: e.target.value })
              }
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <input
              type="number"
              placeholder="Width"
              value={shippingDimensions.width}
              onChange={(e) =>
                setShippingDimensions({ ...shippingDimensions, width: e.target.value })
              }
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <input
              type="number"
              placeholder="Height"
              value={shippingDimensions.height}
              onChange={(e) =>
                setShippingDimensions({ ...shippingDimensions, height: e.target.value })
              }
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </div>
        </div>

        {/* Marketing Features Section */}
        <div className='columnsArea'>
          <div className='column'>
            <h3>Marketing Features</h3>
            {marketingFeatures.map((feature, index) => (
              <input
                key={index}
                type="text"
                placeholder={`Feature ${index + 1}`}
                value={feature}
                onChange={(e) => handleFeatureChange(index, e.target.value)}
                style={{ width: '50%', marginBottom: '10px' }}
              />
            ))}
            <button type="button" onClick={handleAddMarketingFeature} style={{ marginTop: '10px' }}>
              Add Marketing Feature
            </button>
          </div>
        </div>

        {/* Product Options Section */}
        <div className='columnsArea'>
          <div className='column' style={{width: '100%'}}>
            <h3>Product Options</h3>
            {productOptions.map((option, index) => (
              <div key={index} style={{ margin: '10px auto', display: 'flex' }}>
                <select
                  style={{padding: '5px', height: '45px', margin: 'auto auto'}}
                  value={option.size}
                  onChange={(e) => {
                    const newOptions = [...productOptions];
                    newOptions[index].size = e.target.value;
                    setProductOptions(newOptions);
                    
                  }}
                >
                  {productSize.map((sizeOption, sizeIndex) => (
                    <option key={sizeIndex} value={sizeOption}>
                      {sizeOption}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  placeholder="Price"
                  value={option.price}
                  onChange={(e) => {
                    const newOptions = [...productOptions];
                    newOptions[index].price = e.target.value;
                    setProductOptions(newOptions);
                  }}
                  style={{ marginRight: '10px' }}
                />
                <input
                  type="number"
                  placeholder="Cost"
                  value={option.cost}
                  onChange={(e) => {
                    const newOptions = [...productOptions];
                    newOptions[index].cost = e.target.value;
                    setProductOptions(newOptions);
                  }}
                  style={{ marginRight: '10px' }}
                />
                <input
                  type="text"
                  placeholder="Price ID"
                  value={option.priceId}
                  onChange={(e) => {
                    const newOptions = [...productOptions];
                    newOptions[index].priceId = e.target.value;
                    setProductOptions(newOptions);
                  }}
                  style={{ marginRight: '10px' }}
                />
                <input
                  type="text"
                  placeholder="Product ID"
                  value={option.productId}
                  onChange={(e) => {
                    const newOptions = [...productOptions];
                    newOptions[index].productId = e.target.value;
                    setProductOptions(newOptions);
                  }}
                  style={{ marginRight: '10px' }}
                />
              </div>
            ))}
            <button type="button" onClick={handleAddProductOption}>
              Add Product Option
            </button>
          </div>
        </div>

        {/* Cover Options Section */}
        <div className='columnsArea'>
          <div className='column'>
            <h3>Cover Options</h3>
            {coverOptions.map((option, index) => (
              <div key={index} style={{ margin: '10px auto', display: 'flex' }}>
                <input
                  type="text"
                  placeholder="Cover Type"
                  value={option.coverType}
                  onChange={(e) => {
                    const newOptions = [...coverOptions];
                    newOptions[index].coverType = e.target.value;
                    setCoverOptions(newOptions);
                  }}
                  style={{ marginRight: '10px' }}
                />
                <input
                  type="text"
                  placeholder="Cover Name"
                  value={option.coverName}
                  onChange={(e) => {
                    const newOptions = [...coverOptions];
                    newOptions[index].coverName = e.target.value;
                    setCoverOptions(newOptions);
                  }}
                  style={{ marginRight: '10px' }}
                />
              </div>
            ))}
            <button type="button" onClick={handleAddCoverOption}>
              Add Cover Option
            </button>
            <div style={{display: 'flex', alignItems: 'center', margin: '10px auto'}}>

              <label style={{width: '50%'}}>Cover Option Photos</label>
              <input
                type="file"
                multiple
                onChange={(e) => setCoverPhotos(e.target.files)}
                style={{ width: '50%', marginTop: '10px' }}
                />
              </div>
          </div>
        </div>

        <button type="submit" style={{ marginTop: '20px' }}>Submit</button>
      </form>
    </main>
  );
}
