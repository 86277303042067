import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // For navigation arrows
import './CoverMaterialInfo.css';

export default function CoverMaterialInfo({ data }) {
  const [activeTab, setActiveTab] = useState(0); // To track the active cover type tab
  const [currentImage, setCurrentImage] = useState(0); // To track the current image in the carousel
  const [intervalId, setIntervalId] = useState(null); // To handle auto-rotation

  // Check if data and coverOptions are available, and provide default values if not
  const coverOptions = data?.coverOptions || []; // Safely access coverOptions or default to an empty array

  // Extract unique cover types outside of conditional logic
  const uniqueCoverTypes = coverOptions.length > 0 
    ? Array.from(new Set(coverOptions.map(option => option.coverType))) 
    : [];

  // Handle tab change
  const handleTabClick = (index) => {
    setActiveTab(index);
    setCurrentImage(0); // Reset to the first image when switching tabs
  };

  // Handle image change with arrow clicks - ensure looping behavior
  const handlePrevClick = () => {
    const filteredOptions = coverOptions.filter(option => option.coverType === uniqueCoverTypes[activeTab]);
    setCurrentImage(prev => (prev === 0 ? filteredOptions.length - 1 : prev - 1));
  };

  const handleNextClick = () => {
    const filteredOptions = coverOptions.filter(option => option.coverType === uniqueCoverTypes[activeTab]);
    setCurrentImage(prev => (prev === filteredOptions.length - 1 ? 0 : prev + 1));
  };

  // Auto-rotate images every 3 seconds
  useEffect(() => {
    const id = setInterval(() => {
      handleNextClick();
    }, 3000);
    setIntervalId(id);

    return () => clearInterval(id); // Clean up on component unmount
  }, [currentImage, activeTab]);

  // Handle dot click
  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  // If coverOptions is empty, render a loading state
  if (coverOptions.length === 0) {
    return <div>Loading...</div>;
  }
  

  return (
    <div className="carousel-container">
  {/* Tabs for cover types */}
  <div className="tabs">
    {uniqueCoverTypes.map((coverType, index) => (
      <button
        key={index}
        className={`tab ${index === activeTab ? 'active' : ''}`}
        onClick={() => handleTabClick(index)}
        style={{borderRadius: '15px'}}
      >
        {coverType}
      </button>
    ))}
  </div>

  {/* Carousel for the selected cover type */}
  <div className="carousel">
    <FaChevronLeft className="arrow left" onClick={handlePrevClick} />
    {coverOptions
      .filter(option => option.coverType === uniqueCoverTypes[activeTab])
      .map((option, index) => (
        <div
          key={option._id}
          className={`carousel-item ${index === currentImage ? 'active' : ''}`}
        >
          <img src={option.url} alt={option.coverName} />
          <div className="image-info">
            <h5>{option.coverName}</h5>
          </div>
        </div>
      ))}
    <FaChevronRight className="arrow right" onClick={handleNextClick} />
  </div>

  {/* Dot indicators */}
  <div className="dots">
    {coverOptions
      .filter(option => option.coverType === uniqueCoverTypes[activeTab])
      .map((_, index) => (
        <span
          key={index}
          className={`dot ${index === currentImage ? 'active' : ''}`}
          onClick={() => handleDotClick(index)}
        />
      ))}
  </div>
</div>

  );
}
