import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './orderDetailsPage.css';
import { UserContext } from '../../../context/UserProvider'; // Correct import of UserContext
import { useUser } from "../../../context/UserProvider";

export default function OrderDetailPage() {
  const navigate = useNavigate()
  const { orderId } = useParams();
  const { user } = useContext(UserContext); // Get user from context
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(''); // Track the current status

  const { cognitoUser, mongoUser } = useUser();

  const statusItems = [
    'Awaiting user images', 'pending', 'Sent to editing team', 'Editing started', 'Editing completed', 
    'Send to manufacturing', 'Manufacturing', 'Shipped', 'Delivered', 'Cancelled', 'Returned', 
    'Waiting for client approval', 'album-approved', 'partially-shipped'
  ];

  // Handle status update when dropdown value changes
  const handleStatusUpdate = async (newStatus) => {
    try {
      const updateData = {
        status: newStatus,
        user: {
          firstName: user.firstName,  // Get user data from context
          lastName: user.lastName,
        },
        readyForEditing: order.readyForEditing // Optionally include additional order data
      };

      // Update status on the backend
      const response = await axios.put(`${import.meta.env.BASE_API}/orders/api/update-status/${orderId}`, updateData);
      console.log('Status updated:', response.data);

      // Optionally, refresh the order data after status update
      fetchOrder(); // Optional, if you want to refetch the order data
    } catch (err) {
      console.error('Error updating status:', err);
    }
  };

  // Fetch order data
  const fetchOrder = async () => {
    try {
      const response = await axios.get(`https://9equ2m7eha.execute-api.us-east-1.amazonaws.com/dev/orders/${mongoUser.id}/${orderId}`);
      if (response.data) {
        setOrder(response.data);
        const latestStatus = response.data.statusHistory[response.data.statusHistory.length - 1]?.status;
        setStatus(latestStatus || ''); // Set the current status from the order's status history
      } else {
        setError('Order not found');
      }
    } catch (err) {
      setError('Error fetching order');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!user){
      navigate('/login')
      return
    }
    fetchOrder();
  }, [ user, orderId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!order) return <div>Order not found</div>;

  return (
    <div className="order-detail-page">
      {/* Order Summary Section */}
      <div className="order-summary">
        <h2>Order Summary</h2>
        <p><strong>Order ID:</strong> {order._id}</p>
        <p><strong>User:</strong> {order.firstName} {order.lastName}</p>
        <p><strong>Total Price:</strong> ${order.totalPrice}</p>
        <p><strong>Payment Status:</strong> {order.paymentStatus}</p>
        <p><strong>Shipping Method:</strong> {order.shippingMethod}</p>
      </div>

      {/* Product and Album List */}
      <div className="product-list">
        <h3>Products</h3>
        {order.products?.length > 0 ? (
          order.products.map((product) => (
            <div key={product._id} className="product-item">
              <p><strong>Product ID:</strong> {product._id}</p>
            </div>
          ))
        ) : (
          <p>No products in this order</p>
        )}

        <h3>Albums</h3>
        {order.albums?.length > 0 ? (
          order.albums.map((album, index) => (
            <div key={index} className="album-item">
              <p><strong>Album Name:</strong> {album.albumName}</p>
              <p><strong>Size:</strong> {album.albumOptions?.size}</p>
              <p><strong>Pages:</strong> {album.albumOptions?.pages}</p>
              <p><strong>Price:</strong> ${album.albumOptions?.price}</p>
              <p><strong>Cover Type:</strong> {album.coverOptions?.coverType}</p>
              <p><strong>Cover Name:</strong> {album.coverOptions?.coverName}</p>
            </div>
          ))
        ) : (
          <p>No albums in this order</p>
        )}
      </div>

      {/* Shipping and Billing Details */}
      <div className="shipping-details">
        <h3>Shipping Address</h3>
        {order.shippingAddress ? (
          <>
            <p>{order.shippingAddress.street}</p>
            <p>{order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zip}</p>
          </>
        ) : (
          <p>No shipping address available</p>
        )}

        <h3>Billing Address</h3>
        {order.billingAddress ? (
          <>
            <p>{order.billingAddress.street}</p>
            <p>{order.billingAddress.city}, {order.billingAddress.state} {order.billingAddress.zip}</p>
          </>
        ) : (
          <p>No billing address available</p>
        )}
      </div>

      {/* Status History */}
      <div className="status-history">
        <h3>Status History</h3>
        {/* Dropdown to select and update the status immediately */}
        <select 
          value={status} 
          onChange={(e) => {
            const newStatus = e.target.value;
            setStatus(newStatus); // Update status in the UI
            handleStatusUpdate(newStatus); // Trigger the backend update
          }}
        >
          {statusItems.map((item) => (
            <option key={item} value={item}>{item}</option>
          ))}
        </select>
        <ul>
          {order.statusHistory?.map((statusItem, index) => (
            <li key={index}>
              <p><strong>Status:</strong> {statusItem.status}</p>
              <p><strong>Updated At:</strong> {new Date(statusItem.updatedAt).toLocaleString()}</p>
              <p><strong>Updated By:</strong> {statusItem.updatedBy?.firstName} {statusItem.updatedBy?.lastName}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
