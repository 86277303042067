

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios'
import './galleryUploadPage.css'
import { useUser } from "../../context/UserProvider";


export default function GalleryUploadPage() {
    const {orderId, clientId} = useParams()
    

    const [orderName, setOrderName] = useState("")
    const [images, setImages] = useState([])
    const [files, setFiles] = useState([])
    const [progress, setProgress] = useState({started: false, pc: 0})
    const [msg, setMsg] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    
    



async function handleSubmit(e) {
    if(files.length === 0){
        alert('No file was selected')
        return
    }
    e.preventDefault()

    const formData = new FormData()
    formData.append('orderName', orderName)
    formData.append('clientId', clientId)
    formData.append('orderId', orderId)
    for (let i=0; i< files.length; i++ ) {
        formData.append(`images`, files[i])
        
    }
    
    setMsg("Uploading...")
    await axios.post(`${import.meta.env.VITE_API}/gallery/gallery-uploads`, formData, {headers: 
        { "Content-Type": 'multipart/form-data' }
    })
    setSubmitted(true)
    setMsg("")
    
}

function handleImages(e){
    console.log(e.target.files)
    setFiles(e.target.files)
    
}

 useEffect(() => {
    axios.get(`${import.meta.env.VITE_API}/gallery/gallery-images/${clientId}/${orderId}`)
    .then((res) => {
        const newImages = res.data
        setImages(newImages)
    })
    

}, [])

    return(
        <main>
            <h1>{submitted? "Your images have been uploaded": "Upload Your Images"}</h1>
            <h5 style={{textAlign: "center"}}>for Order: {orderId}</h5>
            <h4>{msg}</h4>
            <div className="">
                <form onSubmit={handleSubmit}>
                    <label>Select an Image:</label>
                    <input 
                        type="text"
                        name="orderName"
                        value={orderName}
                        placeholder="Order Name"
                        required={true}
                        onChange={(e) => setOrderName(e.target.value)}
                    ></input>
                    <input
                        type="hidden"
                        name="orderId"
                        value={orderId}
                    ></input>
                    <input
                        type="hidden"
                        name="userId"
                        value={clientId}
                    ></input>
                    <input 
                        type="file"
                        multiple='multiple'
                        accept="image/*"
                        onChange={handleImages}
                    ></input>
                    <button>Submit</button>
                </form>
            </div>
            <div className="uploadedImages">
                <h2>Uploaded Images</h2>
                <div className="imageArea">
                    {/* {images.map((item, index) => (
                        <div className="image" style={{width: "30%", objectFit: "cover" }}>
                            <img src={item.url}/>
                            <p>{item.fileName} image.jpg</p>
                        </div>
                    ))} */}
                    <img src="https://albumsandart.s3.us-east-1.amazonaws.com/123456/test123/Albums%20and%20Art%20Logo.png
                    "/>
                    
                </div>
            </div>
        </main>
    )
}