import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import { CartContext } from '../../context/CartContext';
import ReactLoading from 'react-loading';
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { GoDot, GoDotFill } from "react-icons/go";
import './albumProductPage.css';
import axios from 'axios';

// Components
import EmptyNav from '../../components/navbars/EmptyNav';

// Page Size images
import image6x9 from '../../assets/images/6x9.png';
import image8x8 from '../../assets/images/8x8.png';
import image10x10 from '../../assets/images/10x10.png';
import image12x12 from '../../assets/images/12x12.png';
import image12x18 from '../../assets/images/12x18.png';

export default function AlbumProductPage() {
    const { addToCart } = useContext(CartContext);
    const { albumName } = useParams();
    const navigate = useNavigate();
    const isFirstRender = useRef(true);

    // State
    const [imageIndex, setImageIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [sizeImage, setSizeImage] = useState(null);  // Main album image (size-based)
    const [coverImage, setCoverImage] = useState(null);  // Selected cover image (user-chosen)
    const [price, setPrice] = useState('');
    const [data, setData] = useState(null);
    const [pageSelection, setPageSelection] = useState(20);
    const [sizeSelection, setSizeSelection] = useState(null);
    const [pageAvailable, setPageAvailable] = useState([]);
    const [sizeAvailable, setSizeAvailable] = useState([]);
    const [maxImages, setMaxImages] = useState('40');
    const [coverTypes, setCoverTypes] = useState([]);
    const [coverSelection, setCoverSelection] = useState(null);  // Selected cover type

    // Image navigation
    const handleForwardPhoto = () => {
        setImageIndex(index => (index === images.length - 1 ? 0 : index + 1));
    };

    const handleRearPhoto = () => {
        setImageIndex(index => (index === 0 ? images.length - 1 : index - 1));
    };

    // Handle Pages
    const handlePages = (e) => {
        const selectedPages = Number(e.target.value);
        setPageSelection(selectedPages);
        setMaxImages(selectedPages * 2);
    };

    // Handle Size
    const handleSize = (e) => {
        const selectedSize = e.target.id;
        setSizeSelection(selectedSize);

        switch (selectedSize) {
            case '6x9':
                setSizeImage(image6x9);
                break;
            case '8x8':
                setSizeImage(image8x8);
                break;
            case '10x10':
                setSizeImage(image10x10);
                break;
            case '12x12':
                setSizeImage(image12x12);
                break;
            case '12x18':
                setSizeImage(image12x18);
                break;
            default:
                break;
        }

        const pagesForSize = data.albumOptions
            .filter(option => option.size === selectedSize)
            .map(option => option.pages);
        setPageAvailable([...new Set(pagesForSize)]);
        setPageSelection(Math.min(...pagesForSize));
    };

// Handle Cover Type Selection (also sets the default cover for the selected type)
const handleCoverType = (coverType) => {
    console.log("Cover Type Clicked: ", coverType);

    // Filter the covers by the selected coverType
    const filteredCovers = data.coverOptions.filter(option => option.coverType === coverType);

    if (filteredCovers.length > 0) {
        // Automatically set the first cover of the selected type as default
        const defaultCoverForType = filteredCovers[0];

        // Update the cover selection with the default cover of the selected type
        setCoverSelection({
            coverType: defaultCoverForType.coverType,
            coverName: defaultCoverForType.coverName,
            url: defaultCoverForType.url,
        });

        // Update the displayed cover image
        setCoverImage(defaultCoverForType.url);

        console.log("Default Cover for Type: ", defaultCoverForType);
    }
};

// Handle Cover Image Selection (updates both cover image URL and coverName)
const handleCoverImage = (url, coverName) => {
    console.log("Cover Image Clicked: ", coverName);

    // Ensure that the cover image clicked matches the selected coverType and coverName
    const selectedCover = data.coverOptions.find(option =>
        option.coverName === coverName && option.coverType === coverSelection.coverType
    );

    if (selectedCover) {
        // Update the cover selection with the clicked cover image and cover name
        setCoverSelection({
            ...coverSelection,  // Preserve the selected coverType
            coverName: selectedCover.coverName,  // Update coverName
            url: url,  // Update the selected image URL
        });

        // Update the displayed cover image
        setCoverImage(url);

        console.log("Selected Cover: ", selectedCover);
    } else {
        console.error('Cover not found for coverName:', coverName);
    }
};
    // Add to Cart
    const handleAddToCart = () => {
        // Make sure the selected cover is correctly set before adding to the cart
        const selectedCover = coverSelection; // Use coverSelection directly from state
    
        if (data && sizeSelection && pageSelection && selectedCover && coverImage) {
            const selectedAlbumOption = data.albumOptions.find(option =>
                option.size === sizeSelection && option.pages === pageSelection
            );
    
            if (!selectedAlbumOption) {
                alert('Selected album option is not available.');
                return;
            }
    
            // Construct the cart item using the selected options
            const cartItem = {
                id: data._id,
                albumName: data.albumName,
                category: data.category,
                imageUrl: data.images[0]?.url,  // Main album image (size-based)
                price: selectedAlbumOption.price,
                size: sizeSelection,
                pages: selectedAlbumOption.pages,
                albumOptions: {
                    size: selectedAlbumOption.size,
                    pages: selectedAlbumOption.pages,
                    price: selectedAlbumOption.price,
                    optionId: selectedAlbumOption._id,
                    priceId: selectedAlbumOption.priceId,
                    dupPriceId: selectedAlbumOption.dupPriceId,
                },
                coverSelection: {
                    url: coverImage,  // Selected cover image URL
                    coverType: selectedCover.coverType,  // Selected cover type
                    coverName: selectedCover.coverName,  // Selected cover name
                },
                quantity: 1,  // Default quantity
            };
    
            // Add the selected cart item to the cart
            addToCart(cartItem);
            navigate('/cart');
        } else {
            alert('Please select all required options before adding to the cart.');
        }
    };
    
    // Handle Price Calculation
    const handlePrice = () => {
        if (!data || !data.albumOptions) {
            setPrice('N/A');
            return;
        }
        const option = data.albumOptions.find(item => item.size === sizeSelection && item.pages === pageSelection);

        if (option) {
            setPrice(option.price);
        } else {
            setPrice('N/A');
        }
    };

    // Fetch Album Data
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(`https://hieg30o138.execute-api.us-east-1.amazonaws.com/dev/albums/name/${albumName}`);
                setData(response.data);
                setImages(response.data.images);
                setCoverTypes([...new Set(response.data.coverOptions.map(opt => opt.coverType))]);
            } catch (err) {
                console.error('Error fetching data:', err.message);
            }
        };

        if (albumName) {
            getData();
        }
    }, [albumName]);

    // Set Default Cover on Initial Load
    useEffect(() => {
        if (data?.coverOptions && data.coverOptions.length > 0) {
            const defaultCover = data.coverOptions[0];

            if (isFirstRender.current) {
                setCoverSelection(defaultCover);
                setCoverImage(defaultCover?.url);
                isFirstRender.current = false;
            }
        }
    }, [data]); // This will only run when 'data' changes

    // Set Available Sizes
    useEffect(() => {
        if (data) {
            const sizes = [...new Set(data.albumOptions.map(option => option.size))];
            setSizeAvailable(sizes);

            if (sizes.includes('6x9')) {
                setSizeSelection('6x9');
                setSizeImage(image6x9);
            } else if (sizes.length > 0) {
                setSizeSelection(sizes[0]);
                setSizeImage(image6x9);
            }
        }
    }, [data]);

    // Recalculate Price
    useEffect(() => {
        handlePrice();
    }, [sizeSelection, pageSelection, data]);

    // Set Default Cover on Page Load (or when album data is updated)
useEffect(() => {
    if (data?.coverOptions && data.coverOptions.length > 0) {
        const defaultCover = data.coverOptions[0];  // Set the default cover to the first item in coverOptions

        // Set the default cover when the page first loads
        setCoverSelection({
            coverType: defaultCover.coverType,
            coverName: defaultCover.coverName,
            url: defaultCover.url,
        });

        // Set the default cover image
        setCoverImage(defaultCover.url);

        console.log("Default Cover on Load: ", defaultCover);
    }
}, [data]); 

    // Filtered Cover Images
    const filteredImages = data && Array.isArray(data.coverOptions)
        ? data.coverOptions.filter(option => option.coverType === coverSelection?.coverType)
        : [];

    return (
        <main>
            <EmptyNav />
            {data === null ? (
                <div style={{ margin: '10px auto' }}>
                    <ReactLoading size={50} type='spin' />
                </div>
            ) : (
                <div className='productArea'>
                    <div className='itemImage'>
                        <FaChevronCircleLeft color='#967f71' id='leftArrow' size={25} onClick={handleRearPhoto} />
                        <img src={images[imageIndex]?.url} alt="Album" style={{ width: '85%' }} />
                        <FaChevronCircleRight color='#967f71' id='rightArrow' size={25} onClick={handleForwardPhoto} />
                        <div className='imageActiveCircle'>
                            {images.map((item, key) => (
                                <div style={{ display: 'flex' }} key={key}>
                                    {imageIndex === key ? (
                                        <GoDotFill size={20} color='#967f71' />
                                    ) : (
                                        <GoDot size={20} color='#967f71' />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='itemDescription' style={{ marginTop: '100px' }}>
                        <h1>{data.albumName}</h1>
                        {albumName !== 'Press-Book' || 'Economy' &&
                            <p>Choose Your Cover</p>
                        }

                        <div className='materialType'>
                            {coverTypes.length > 0 ? (
                                coverTypes.map((item, index) => (
                                    <h6
                                        key={index}
                                        id={item}
                                        onClick={() => handleCoverType(item)}  // Handle only cover type selection
                                        className={coverSelection?.coverType === item ? 'active' : ''}  // Apply active class if this is the selected type
                                    >
                                        {item}
                                    </h6>
                                ))
                            ) : (
                                albumName === 'Economy' && coverTypes.length === 0 &&

                                    <p>Black Leather</p>
                                
                            )}
                        </div>

                        <div className='coverImages'>
                            {coverSelection && filteredImages.length > 0 ? (
                              albumName !== 'Press-book' &&  filteredImages.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.url}
                                        alt={image.coverName}
                                        className={`cover-image ${coverImage === image.url ? 'selected' : ''}`}
                                        onClick={() => handleCoverImage(image.url, image.coverName)}  // Pass coverName here
                                    />
                                ))
                            ) : coverSelection ? (
                                <p>No images for this cover type.</p>
                            ) : (
                                <p>Select a cover type to see images.</p>
                            )}
                        </div>


                        <div className="sizeContainer">
                            <p>Choose Your Size</p>
                            <div className="sizeArea">
                                {sizeAvailable.length > 0 ? (
                                    sizeAvailable.map((size, index) => (
                                        <p
                                            key={index}
                                            id={size}
                                            onClick={handleSize}
                                            className={sizeSelection === size ? 'active' : undefined}
                                        >
                                            {size}
                                        </p>
                                    ))
                                ) : (
                                    <p>No sizes available.</p>
                                )}
                            </div>

                            <div className="imageArea">
                                <img
                                    src={sizeImage}
                                    alt="Selected Album Size"
                                    style={{
                                        width: 'auto',
                                        height: '100px',
                                        borderRadius: '5px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="addPages">
                                {pageAvailable.length > 0 ? (
                                    <select onChange={handlePages} value={pageSelection}>
                                        {pageAvailable.map((pages, index) => (
                                            <option key={index} value={pages}>
                                                {pages} Pages
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    albumName === "economy" ? 
                                    <p>No pages available.</p>
                                    :
                                    ''
                                )}
                            </div>

                            <div className="pageCount">
                                <h6 style={{textAlign: 'center'}}>Max Images for Design: {maxImages}</h6>
                            </div>
                        </div>

                        <div className='totalPrice'>
                            <h4 style={{textAlign: 'center'}}>Item Price: ${price}</h4>
                            <button onClick={handleAddToCart} style={{backgroundColor: 'royalblue', color: 'white'}}>Add to Cart</button>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}
