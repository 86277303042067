import './navbarProduct.css'
import {Link} from 'react-router-dom'

export default function NavbarProduct(props) {

    return(
        <div className='navbarProduct' style={{justifyContent: 'center'}}>
            <div className='navbarImage'>
                <img src={props.image} />
            </div>
            <div className='infoArea'>
                <div className='itemName'>
                    <h5>{props.name}</h5>
                </div>
                <div className='linksArea' >
                    <Link to={`${props.learnLink}`} style={{color: '#3c3c3c', textDecoration: 'underline'}}>Learn</Link>
                    <Link to={`${props.orderLink}`} style={{color: '#3c3c3c', textDecoration: 'underline'}}>Order</Link>     
                </div>
            </div>
        </div>
    )
}